// framework
import { useState, useEffect } from "react";
// kendo
import { DataResult, State } from "@progress/kendo-data-query";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridDataStateChangeEvent, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { FilterChangeEvent, NumericFilter, BooleanFilter, Operators, DateFilter } from "@progress/kendo-react-data-tools";
// common
import ODataReader from "../../../common/odata/ODataReader";
import * as GlobalHelpers from "../../../common/GlobalHelpers";
import { useUserToken } from "../../../common/shell/shellHelper";
import GridFilter from "../../../common/kendoGrid/GridFilter";
import { getDateFilter, getNumericFilter, getTextFilter } from "../../../common/kendoGrid/GridFilterOptions";
import { TextBoxFilter } from "../../../common/kendoGrid/GridFilterTextBoxFilter";
// models
import { ICoreLibraryDataRow } from "./models";
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";
import CommentSummaryView from "../../../common/commentSummary/commentSummaryView";
import ExternalLinkView from "../../../common/externalLink/ExternalLinkView";

interface IProps {
    selectionVersion: number;
}

export default function Controller(props: IProps) {
    const userToken = useUserToken();

    const [pageSize, setPageSize] = useState(10);
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
            {
                field: "WellName",
                operator: "contains",
                value: "",
            },
        ],
    };

    const [gridState, setGridDataState] = useState<State>({
        sort: [
            { field: "WellName", dir: "asc" },
            { field: "BoreholeName", dir: "asc" },
            { field: "CoreSampleType", dir: "asc" },
        ],
        take: pageSize,
        skip: 0,
        filter: initialFilter,
    });

    const [filter, setFilter] = useState<CompositeFilterDescriptor>(initialFilter);
    function onFilterChange(e: FilterChangeEvent): void {
        setFilter(e.filter);
        setGridDataState({ ...gridState, filter: e.filter });
    }

    const [data, setData] = useState<DataResult>({ data: [], total: 0 });
    function onDataChange(data: DataResult): void {
        setData(data);
    }

    function mapDataRow(dataRow: any): ICoreLibraryDataRow {
        return {
            CoreLibraryId: dataRow.CoreLibraryId,
            WellId: dataRow.WellId,
            Uwi: dataRow.Uwi,
            WellName: dataRow.WellName,
            BoreholeId: dataRow.BoreholeId,
            Ubhi: dataRow.Ubhi,
            BoreholeName: dataRow.BoreholeName,
            CoreRepository: dataRow.CoreRepository,
            Basin: dataRow.Basin,
            IsOffshore: dataRow.IsOffshore,
            Jurisdiction: dataRow.Jurisdiction,
            NopimsJurisdiction: dataRow.NopimsJurisdiction,
            CoreSampleType: dataRow.CoreSampleType,
            StartDepth: dataRow.StartDepth,
            EndDepth: dataRow.EndDepth,
            ViewTypeName: dataRow.ViewTypeName,
            BoxTrayNumber: dataRow.BoxTrayNumber,
            BoxTrayCount: dataRow.BoxTrayCount,
            AccessionCardUrl: dataRow.AccessionCardUrl,
            RigReleaseDate: dataRow.RigReleaseDate ? new Date(dataRow.RigReleaseDate) : null,
            WellBoreholeType: dataRow.WellBoreholeType,
            BoreholeReason: dataRow.BoreholeReason,
            Comments: dataRow.Comments,
        };
    }

    // ensure we don't get stuck on an empty grid page
    useEffect(() => {
        if (data.data.length === 0 && data.total > 0 && gridState && gridState.skip! > 0) setGridDataState({ ...gridState, skip: 0 });
    }, [data, gridState]);

    // return
    return (
        <>
            <GridFilter
                value={filter}
                onChange={onFilterChange}
                fields={[
                    { name: "Uwi", label: "Well ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "WellName", label: "Well", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "Ubhi", label: "Borehole ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "BoreholeName", label: "Borehole", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "CoreRepository", label: "Core Repository", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "Basin", label: "Basin", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "IsOffshore", label: "Offshore", filter: BooleanFilter, operators: Operators.boolean },
                    { name: "Jurisdiction", label: "State", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "NopimsJurisdiction", label: "Jurisdiction", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "CoreSampleType", label: "Sample Type", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "StartDepth", label: "Start Depth (m)", filter: NumericFilter, operators: getNumericFilter(true) },
                    { name: "EndDepth", label: "End Depth (m)", filter: NumericFilter, operators: getNumericFilter(true) },
                    { name: "ViewTypeName", label: "View Type", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "BoxTrayNumber", label: "Box/Tray", filter: NumericFilter, operators: getNumericFilter(true) },
                    { name: "BoxTrayCount", label: "Number of Trays", filter: NumericFilter, operators: getNumericFilter(true) },
                    { name: "AccessionCardUrl", label: "Accession Card", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "RigReleaseDate", label: "Rig Release Date", filter: DateFilter, operators: getDateFilter(true) },
                    { name: "WellBoreholeType", label: "Borehole Type", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "BoreholeReason", label: "Borehole Reason", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "Comments", label: "Comments", filter: TextBoxFilter, operators: getTextFilter(true) },
                ]}
            />
            {userToken && (
                <ODataReader
                    url={`odata/v1/public/nopims/well/PublicNopimsWells/GetCoreLibrariesForSelection(userToken=${userToken})`}
                    showContentAsBusyElementId="WellCoreLibrariesSearchResultsGrid"
                    dataState={gridState}
                    onDataReceived={onDataChange}
                    mapDataRow={mapDataRow}
                    changeToken={props.selectionVersion}
                />
            )}
            <div className="mb-4">
                <Grid
                    id="WellCoreLibrariesSearchResultsGrid"
                    sortable
                    pageable={{ pageSizes: [10, 20, 50, 100], pageSizeValue: pageSize, responsive: false }}
                    onPageChange={(e: GridPageChangeEvent) => {
                        setPageSize(e.page.take);
                        setGridDataState({ ...gridState, take: e.page.take, skip: e.page.skip });
                    }}
                    resizable
                    navigatable
                    {...gridState}
                    data={data}
                    onDataStateChange={(e: GridDataStateChangeEvent) => setGridDataState(e.dataState)}
                >
                    <GridColumn field="Uwi" filter="text" title="Well ID" width={150} />
                    <GridColumn field="WellName" filter="text" title="Well" width={250} />
                    <GridColumn field="Ubhi" filter="text" title="Borehole ID" width={150} />
                    <GridColumn field="BoreholeName" filter="text" title="Borehole" width={250} />
                    <GridColumn field="CoreRepository" filter="text" title="Core Repository" width={250} />
                    <GridColumn field="Basin" title="Basin" width={250} />
                    <GridColumn
                        title="Offshore"
                        width={120}
                        field="IsOffshore"
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props} className="k-command-cell">
                                {props.dataItem.IsOffshore ? "Y" : "N"}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="Jurisdiction" title="State" width={200} />
                    <GridColumn field="NopimsJurisdiction" title="Jurisdiction" width={250} />
                    <GridColumn field="CoreSampleType" filter="text" title="Sample Type" width={240} />
                    <GridColumn field="StartDepth" title="Start Depth (m)" format={"{0:N}"} width={150} />
                    <GridColumn field="EndDepth" title="End Depth (m)" format={"{0:N}"} width={150} />
                    <GridColumn field="ViewTypeName" filter="text" title="View Type" width={150} />
                    <GridColumn field="BoxTrayNumber" filter="text" title="Box/Tray" width={160} />
                    <GridColumn field="BoxTrayCount" filter="text" title="Number of Trays" width={160} />
                    <GridColumn
                        field="AccessionCardUrl"
                        title="Accession Card"
                        width={220}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AccessionCardUrl && <ExternalLinkView href={props.dataItem.AccessionCardUrl}>Download</ExternalLinkView>}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="RigReleaseDate" title="Rig Release Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={180} />
                    <GridColumn field="WellBoreholeType" title="Borehole Type" width={150} />
                    <GridColumn field="BoreholeReason" title="Borehole Reason" width={200} />
                    <GridColumn
                        title="Comments"
                        width={460}
                        field="Comments"
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props} className="k-command-cell">
                                <CommentSummaryView comment={props.dataItem.Comments} maxSummaryLength={50} title="Comments"></CommentSummaryView>
                            </CustomGridCell>
                        )}
                    />
                </Grid>
            </div>
        </>
    );
}
