// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { setLayout } from "../../shell/redux/shellActions";

function MapController(props: any) {
    const { setLayout } = props;

    // initial load
    useEffect(() => {
        setLayout("Interactive Map");
    }, [setLayout]);

    // return
    const url = process.env.REACT_APP_MAP_OPGGS_AND_OEI;
    return (
        <>
            <iframe
                title="An interactive map presenting the geographical offshore locations of active Titles that exist in Australian territorial waters and other Title related data. The map is presented in an iframe with data sourced from NOPTA's ArcGIS online mapping service."
                src={url}
                style={{ height: "75vh", width: "100%", border: "none" }}
            ></iframe>
        </>
    );
}

// connect redux
export default connect((state: any) => ({}), { setLayout })(MapController);
