import { takeEvery, call, put } from "redux-saga/effects";
import * as Client from "../../../api/Client";
import { createOeiLicenceOpenClient } from "../../../api/clientFactory";
import { simpleCall } from "../../../api/callHelper";
import { setBusyAction, clearBusyAction } from "../../../shell/redux/shellActions";
import * as Actions from "./openLicenceActions";

export default function* watcherSaga() {
    yield takeEvery(Actions.openLicenceActions.GetLicenceIdRequest, workerSaga);
}

function* workerSaga(action: Actions.IOpenLicenceActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicOpenLicenceIdResponseDto = yield call(query, action.licenceNumber!);
        // post response
        yield put(Actions.getLicenceIdResponse(response.licenceId));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function query(licenceNumber: string): Promise<Client.GetPublicOpenLicenceIdResponseDto> {
    const client = createOeiLicenceOpenClient();
    const request = new Client.GetPublicOpenLicenceIdRequestDto({ licenceNumber: licenceNumber });
    const response = await simpleCall(() => client.getPublicOpenLicenceId(request));
    return response;
}
