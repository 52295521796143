// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";

export interface IReferenceDataViewModel {
    honorifics: Client.GetSubmitPublicNopimsBasketReferenceDataResponseHonorificDto[];
    countries: Client.GetSubmitPublicNopimsBasketReferenceDataResponseCountryDto[];
    countrySubDivisions: Client.GetSubmitPublicNopimsBasketReferenceDataResponseCountrySubDivisionDto[];
}

export interface ISubmitBasketViewModel {
    customerHonorific?: Client.GetCreatePublicFeedbackReferenceDataResponseHonorificDto | undefined;
    customerFirstName?: string | undefined;
    customerLastName?: string | undefined;
    customerEmailAddress?: string | undefined;
    customerContactNumber?: string | undefined;
    customerCompanyName?: string | undefined;
    customerAddress?: string | undefined;
    customerSuburb?: string | undefined;
    customerPostcode?: string | undefined;
    customerCountry?: Client.GetSubmitPublicNopimsBasketReferenceDataResponseCountryDto | undefined;
    customerCountrySubdivision?: Client.GetSubmitPublicNopimsBasketReferenceDataResponseCountrySubDivisionDto | undefined;
    customerComments?: string | undefined;
}

export interface IRootViewModel {
    userToken: string;
    statusMessages?: Client.StatusMessagesDto;
    isBusy: boolean;
    isDirty: boolean;
    referenceData: IReferenceDataViewModel;
    submitDetails: ISubmitBasketViewModel;

    setBusy(isBusy: boolean): IRootViewModel;
    refreshReferenceData(response: Client.GetSubmitPublicNopimsBasketReferenceDataRequestDto): IRootViewModel;
    refreshSubmitDetails(feedback: ISubmitBasketViewModel): IRootViewModel;
    refreshSubmitResponse(response: Client.SubmitPublicNopimsBasketResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor(userToken: string) {
        this.userToken = userToken;
        this.statusMessages = undefined;
        this.isBusy = false;
        this.isDirty = false;
        this.submitDetails = {};
        this.referenceData = {
            honorifics: [],
            countries: [],
            countrySubDivisions: [],
        };
    }

    public userToken: string;
    public statusMessages?: Client.StatusMessagesDto;
    public isBusy: boolean;
    public isDirty: boolean;
    public submitDetails: ISubmitBasketViewModel;
    public referenceData: IReferenceDataViewModel;

    setBusy(isBusy: boolean): IRootViewModel {
        const vm = this._clone();
        vm.isBusy = isBusy;
        return vm;
    }

    refreshReferenceData(response: Client.GetSubmitPublicNopimsBasketReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.referenceData = {
            honorifics: response.honorifics,
            countries: response.countries,
            countrySubDivisions: response.countrySubDivisions,
        };
        this.setDefaultCountry(vm);
        vm.isDirty = false;
        return vm;
    }

    refreshSubmitDetails(submitDetails: ISubmitBasketViewModel): IRootViewModel {
        const vm = this._clone();
        vm.submitDetails.customerHonorific = submitDetails.customerHonorific;
        vm.submitDetails.customerFirstName = submitDetails.customerFirstName;
        vm.submitDetails.customerLastName = submitDetails.customerLastName;
        vm.submitDetails.customerEmailAddress = submitDetails.customerEmailAddress;
        vm.submitDetails.customerContactNumber = submitDetails.customerContactNumber;
        vm.submitDetails.customerCompanyName = submitDetails.customerCompanyName;
        vm.submitDetails.customerAddress = submitDetails.customerAddress;
        vm.submitDetails.customerSuburb = submitDetails.customerSuburb;
        vm.submitDetails.customerPostcode = submitDetails.customerPostcode;
        vm.submitDetails.customerCountry = submitDetails.customerCountry;
        vm.submitDetails.customerCountrySubdivision = submitDetails.customerCountrySubdivision;
        vm.submitDetails.customerComments = submitDetails.customerComments;
        vm.isDirty = true;
        return vm;
    }

    refreshSubmitResponse(response: Client.SubmitPublicNopimsBasketResponseDto): IRootViewModel {
        const vm = this._clone();
        if (response.statusMessages?.isSuccess) {
            vm.isDirty = false;
        }
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    private setDefaultCountry(vm: RootViewModel) {
        vm.submitDetails.customerCountry = vm.referenceData.countries.find((c) => c.name === "Australia");
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.submitDetails = clone(this.submitDetails);
        return vm;
    }
}
