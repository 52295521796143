// framework
import * as Validation from "../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);

    if (!vm.sampleViewModel.borehole) {
        v.addError("Sample.Borehole", "Borehole is required.");
    }

    if (!vm.sampleViewModel.intervalTop) {
        v.addError("Sample.IntervalTop", "Interval Top is required.");
    }

    if (!vm.sampleViewModel.intervalBottom) {
        v.addError("Sample.IntervalBottom", "Interval Bottom is required.");
    }

    if (!vm.sampleViewModel.sampleTypes?.some((st) => st)) {
        v.addError("Sample.SampleTypes", "At least one Sample Type must be provided.");
    }

    return v;
}
