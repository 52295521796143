export function home(): string {
    return "/nopims";
}

export function wells(): string {
    return "/nopims/wells";
}

export function surveys(): string {
    return "/nopims/surveys";
}

export function reprocessing(): string {
    return "/nopims/reprocessing";
}

export function releasedDocuments(): string {
    return "/nopims/releasedDocuments";
}

export function coreLibrary(): string {
    return "/nopims/coreLibrary";
}

export function gisMap(): string {
    return "/nopims/map";
}

export function formsFeedback(): string {
    return "/nopims/forms/feedback";
}

export function formsCoreCuttingsRequest(): string {
    return "/nopims/forms/coresAndCuttingsRequest";
}

export function basket(): string {
    return "/nopims/basket";
}
