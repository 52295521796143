import { Dialog } from "@progress/kendo-react-dialogs";
import { ReactElement, useState } from "react";
import { ControlClass } from "../forms/BootstrapForms";
import bootstrapFormsStyles from "../../common/forms/BootstrapForms.module.css";

export enum Orientation {
    Horizontal,
    Vertical,
}

export interface IListSummaryChild {
    /** The element shown in the grid. */
    displayElement: ReactElement;
    /** Optional - The elements shown in the detailed view pop up. When not provided, the displayElement will be shown instead. */
    popupDisplayElement?: ReactElement;
}

export interface IListSummaryViewProps {
    /** Title used in the details pop up */
    title: string;

    /** The full list of child elements */
    children: IListSummaryChild[];

    /** Optional - Sets the delimiter placed between list items when displaying them in the summary and horizontal view. Defaults to ", ". */
    listDelimiter?: string;

    /** Optional - Sets the maximum number of items to be displayed in the summary. Defaults to 5. */
    maxChildrenDisplayCount?: number;

    /** Optional - Determines how the list of items are displayed when the user requests to view more detail. Defaults to Vertical. */
    popupListOrientation?: Orientation;

    /** Optional - When true then the control fills the parent container it's rendered in. Otherwise, it just takes as much space as it requires*/
    fill?: boolean;
}

export default function ListSummaryView(props: IListSummaryViewProps) {
    const { title, children } = props;

    const [showDetailsDialog, setShowDetailsDialog] = useState(false);

    // Default to Vertical if value has not been provided.
    const popupListOrientation = props.popupListOrientation ?? Orientation.Vertical;
    // Default to 5 if it has not been provided.
    const maxChildrenDisplayCount = props.maxChildrenDisplayCount ?? 5;
    // Default to ',' if it has not been provided.
    const listDelimiter = props.listDelimiter ?? ", ";
    // Default to true if it has not been provided.
    const fill = props.fill ?? true;

    // Determine the number of children elements to display
    const truncateChildrenToDisplay = children && children.length > maxChildrenDisplayCount;
    const childrenToDisplay = truncateChildrenToDisplay ? children.slice(0, maxChildrenDisplayCount) : children;

    function handleMoreClick() {
        setShowDetailsDialog(true);
    }

    function onCloseDetailsDialog() {
        setShowDetailsDialog(false);
    }

    function generateHorizontalItems(children: IListSummaryChild[]): ReactElement {
        return (
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                {children.map((item: IListSummaryChild, index: number) => (
                    <li className="mx-1" style={{ display: "inline-block" }} key={`listItem${item.displayElement.key}`}>
                        {item.popupDisplayElement ?? item.displayElement}
                        {index < children.length - 1 && <>{listDelimiter}</>}
                    </li>
                ))}
            </ul>
        );
    }

    function generateVerticalItems(children: IListSummaryChild[]): ReactElement[] {
        return children.map((item: IListSummaryChild) => (
            <div key={item.displayElement.key} className="row">
                {item.popupDisplayElement ?? item.displayElement}
            </div>
        ));
    }

    function displayMoreButton(): boolean {
        // Show the 'More' button if there's more items to show or a detailed items list has been provided. This is to cover the scenario where the children list is less than the maxSummaryListCount
        // but the children list item name have been truncated to fit in the grid. In that scenario, with out the 'More' appearing, the user never be able to see the full list item details.
        return truncateChildrenToDisplay || childrenToDisplay.some((i) => i.popupDisplayElement);
    }

    return (
        <div className="row">
            <div className="col">
                {childrenToDisplay.map((item: IListSummaryChild, index: number) => (
                    <span key={item.displayElement.key}>
                        {index > 0 && <>{listDelimiter}</>}
                        {item.displayElement}
                    </span>
                ))}
                {truncateChildrenToDisplay && <span>{listDelimiter}…</span>}
                {!fill && displayMoreButton() && (
                    <button className="btn btn-outline-secondary btn-sm mx-2" type="button" title={`Click to view ${title}.`} onClick={handleMoreClick}>
                        More ({children.length})
                    </button>
                )}
            </div>
            {fill && displayMoreButton() && (
                <div className="col-auto">
                    <button className="btn btn-outline-secondary btn-sm mx-2" type="button" title={`Click to view ${title}.`} onClick={handleMoreClick}>
                        More ({children.length})
                    </button>
                </div>
            )}
            {showDetailsDialog && (
                <Dialog title={title} width={800} onClose={onCloseDetailsDialog} autoFocus={true}>
                    <div>
                        <span className={`${ControlClass} ${bootstrapFormsStyles.readonlyTextArea}`}>
                            {popupListOrientation === Orientation.Horizontal && generateHorizontalItems(children)}
                            {popupListOrientation === Orientation.Vertical && generateVerticalItems(children)}
                        </span>
                    </div>
                </Dialog>
            )}
        </div>
    );
}
