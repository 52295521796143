import NopimsPageLayout from "../shell/NopimsPageLayout";
import { nopimsLhsNavItemEnum } from "../shell/NopimsLhsNav";
import DocumentSearchController from "./documentSearch/Controller";

export default function Controller(): React.JSX.Element {
    return (
        <NopimsPageLayout title={["Released Documents Search"]} lhsNavItem={nopimsLhsNavItemEnum.ReleasedDocuments} showGuidance={true}>
            <DocumentSearchController />
        </NopimsPageLayout>
    );
}
