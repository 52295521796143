// framework
import { Route } from "react-router-dom";
// routes
import nopimsRouteBuilder from "../nopims/routeBuilder";
import { FeatureSwitch } from "../common/shell/shellHelper";

export function buildRoutes(featureSwitch: FeatureSwitch): Array<React.ReactElement> {
    let routes = [...nopimsRouteBuilder(featureSwitch)];
    routes = routes.sort((a, b) => sort(a.isDefault, b.isDefault));

    return routes.map((r) => <Route key={r.path ?? "(null)"} path={r.path} exact={r.exact} children={r.element} render={r.render}></Route>);
}

function sort(a: boolean | undefined | null, b: boolean | undefined | null): number {
    const a1 = a ?? false;
    const b1 = b ?? false;

    if (a1 && !b1) return 1;
    if (!a1 && b1) return -1;
    return 0;
}
