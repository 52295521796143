import { XCircleFillIcon } from "@primer/octicons-react";

interface IProps {
    title?: string | undefined;
    toolTip?: string | undefined;
    showContainer: boolean;
    items: Array<any>;
    getItemTitle: (item: any) => string;
    getItemTooltip?: (item: any) => string | undefined;
    onItemRemoved: (item: any) => void;
    onItemsCleared: () => void;
}

export default function SelectedItems(props: IProps): React.JSX.Element {
    const selections = props.items.map((i) => (
        <span key={JSON.stringify(i)} className="me-1">
            <SelectionButton
                itemName={props.getItemTitle(i)}
                title={`Click to remove this ${props.getItemTooltip ? props.getItemTooltip(i) : props.title ?? "item"}.`}
                buttonStyle="secondary"
                onClick={() => props.onItemRemoved(i)}
            />
        </span>
    ));

    function ShowSelections(): React.JSX.Element {
        return (
            <>
                {selections.length === 0 ? (
                    <span>No selections have been made.</span>
                ) : (
                    <div>
                        {selections}
                        {selections.length > 0 && (
                            <SelectionButton itemName="Remove All" title={`Click to remove all ${props.title ?? "item"}s.`} buttonStyle="primary" onClick={() => props.onItemsCleared()} />
                        )}
                    </div>
                )}
            </>
        );
    }

    if (props.showContainer) {
        return (
            <div className="card mb-2">
                <div className="card-header px-2 py-1">{props.title ? `Selected ${props.title}s` : "Selections"}</div>
                <div className="card-body p-2">
                    <ShowSelections />
                </div>
            </div>
        );
    } else {
        return <ShowSelections />;
    }
}

function SelectionButton(props: { itemName: string; title: string; onClick: () => void; buttonStyle: "primary" | "secondary" }): JSX.Element {
    return (
        <span className={"badge text-bg-" + props.buttonStyle + " px-2 pt-0 pb-0 mb-1"}>
            <div className="d-flex align-items-center">
                <span className="fw-normal fs-6 me-1 text-wrap">{props.itemName}</span>
                <button type="button" title={props.title} aria-label="Close" className="btn btn-sm p-0 m-1 me-0" onClick={props.onClick}>
                    <XCircleFillIcon fill="white" />
                </button>
            </div>
        </span>
    );
}
