// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";
// models
import { ISampleViewModel } from "../models/models";

export interface IRootViewModel {
    sampleViewModel: ISampleViewModel;

    onDetailsChanged(values: ISampleViewModel): IRootViewModel;

    referenceData: {
        boreholes: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseBoreholeDto[];
        sampleTypes: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseSampleTypeDto[];
    };
}

export class RootViewModel implements IRootViewModel {
    constructor(boreholes: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseBoreholeDto[], sampleTypes: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseSampleTypeDto[]) {
        this.sampleViewModel = {
            uniqueId: crypto.randomUUID(),
            sampleTypes: [],
        };
        this.referenceData = {
            boreholes: boreholes,
            sampleTypes: sampleTypes,
        };
    }

    public sampleViewModel: ISampleViewModel;

    public onDetailsChanged(values: ISampleViewModel): IRootViewModel {
        const vm = this._clone();
        vm.sampleViewModel = { ...vm.sampleViewModel, ...values };
        return vm;
    }

    referenceData: {
        boreholes: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseBoreholeDto[];
        sampleTypes: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseSampleTypeDto[];
    };

    private _clone(): IRootViewModel {
        const vm = clone(this);
        return vm;
    }
}
