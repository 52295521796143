// framework
import { useState } from "react";
// kendo
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import KendoLargeComboBox from "../../../../common/kendo/KendoLargeComboBox";
import KendoNumericTextBox from "../../../../common/kendo/KendoNumericTextBox";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
// common
import * as Forms from "../../../../common/forms/BootstrapForms";
import * as toastHelper from "../../../../common/toastHelper";
import { ValidationVisibilityEnum } from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
// api
import * as Client from "../../../../api/Client";
// models
import { IRootViewModel, RootViewModel } from "./models";
import { ISampleViewModel } from "../models/models";
import validator from "./validator";

interface IProps {
    boreholes: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseBoreholeDto[];
    sampleTypes: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseSampleTypeDto[];
    onAdd: (sample: ISampleViewModel) => void;
    onCancel: () => void;
}

export default function Controller(props: IProps): JSX.Element {
    const [vm, setVm] = useState<IRootViewModel>(new RootViewModel(props.boreholes, props.sampleTypes));

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    const boreholes = vm.referenceData.boreholes;
    const [sampleTypeData, setSampleTypeData] = useState(vm.referenceData.sampleTypes.slice());

    function onChange(values: ISampleViewModel) {
        setVm(vm.onDetailsChanged(values));
    }

    function onAdd() {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        props.onAdd(vm.sampleViewModel);
    }

    function onCancel() {
        props.onCancel();
    }

    return (
        <Dialog title="Add Required Sample" width={600} height={800} onClose={onCancel}>
            <Forms.Group>
                <Forms.Row>
                    <Forms.FullColumn>
                        <Forms.Label htmlFor="boreholeField">Borehole</Forms.Label>
                        <KendoLargeComboBox
                            id="boreholeField"
                            data={boreholes}
                            keyField="id"
                            textField="displayText"
                            filterable={true}
                            suggest={true}
                            value={vm.sampleViewModel.borehole}
                            valid={v.applyValidity("Sample.Borehole")}
                            onChange={(value) => onChange({ ...vm.sampleViewModel, borehole: value })}
                        />
                        <ValidationMessageView name="Sample.Borehole" validationModel={v} />
                    </Forms.FullColumn>
                </Forms.Row>
            </Forms.Group>
            <Forms.Group>
                <Forms.Row>
                    <Forms.FullColumn>
                        <Forms.Label htmlFor="intervalTopField">Interval Top (m)</Forms.Label>
                        <KendoNumericTextBox
                            id="intervalTopField"
                            value={vm.sampleViewModel.intervalTop}
                            format="n3"
                            valid={v.applyValidityForKendo("Sample.IntervalTop")}
                            onChange={(value) => onChange({ ...vm.sampleViewModel, intervalTop: value })}
                            onBlur={() => {}}
                        />
                        <ValidationMessageView name="Sample.IntervalTop" validationModel={v} />
                    </Forms.FullColumn>
                </Forms.Row>
            </Forms.Group>
            <Forms.Group>
                <Forms.Row>
                    <Forms.FullColumn>
                        <Forms.Label htmlFor="intervalBottomField">Interval Bottom (m)</Forms.Label>
                        <KendoNumericTextBox
                            id="intervalBottomField"
                            value={vm.sampleViewModel.intervalBottom}
                            format="n3"
                            valid={v.applyValidityForKendo("Sample.IntervalBottom")}
                            onChange={(value) => onChange({ ...vm.sampleViewModel, intervalBottom: value })}
                            onBlur={() => {}}
                        />
                        <ValidationMessageView name="Sample.IntervalBottom" validationModel={v} />
                    </Forms.FullColumn>
                </Forms.Row>
            </Forms.Group>
            <Forms.Group>
                <Forms.Row>
                    <Forms.FullColumn>
                        <Forms.Label htmlFor="sampleTypesField">Sample Types</Forms.Label>
                        <MultiSelect
                            id="sampleTypesField"
                            value={vm.sampleViewModel.sampleTypes}
                            data={sampleTypeData}
                            dataItemKey="id"
                            textField="name"
                            valid={v.applyValidityForKendo("Sample.SampleTypes")}
                            filterable={true}
                            onFilterChange={(event) => setSampleTypeData(filterBy(vm.referenceData.sampleTypes.slice(), event.filter))}
                            onChange={(event) =>
                                onChange({
                                    ...vm.sampleViewModel,
                                    sampleTypes: event.value.sort(
                                        (
                                            a: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseSampleTypeDto,
                                            b: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseSampleTypeDto
                                        ) => a.name.localeCompare(b.name)
                                    ),
                                })
                            }
                        />
                        <ValidationMessageView name="Sample.SampleTypes" validationModel={v} />
                    </Forms.FullColumn>
                </Forms.Row>
            </Forms.Group>
            <DialogActionsBar>
                <button type="button" className="btn btn-secondary me-2" onClick={onAdd}>
                    Add
                </button>
                <button type="button" className="btn btn-secondary me-2" onClick={onCancel}>
                    Cancel
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}
