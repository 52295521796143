// framework
import { takeEvery, call, put } from "redux-saga/effects";
// redux
import { IOeiApplicationTrackingActions, oeiApplicationTrackingActions, getOeiApplicationTrackingResponseAction } from "./oeiApplicationTrackingActions";
// api
import { createOeiApplicationsClient } from "../../../../api/clientFactory";
import { simpleCall } from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../../../shell/redux/shellActions";

export default function* watcherSaga() {
    yield takeEvery(oeiApplicationTrackingActions.GetOeiApplicationTrackingRequest, workerSaga);
}

function* workerSaga(action: IOeiApplicationTrackingActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicOeiApplicationsTrackingResponseDto = yield call(query, action.isCompleted, action.applicationTrackingType);
        // post response
        yield put(getOeiApplicationTrackingResponseAction(response));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function query(isCompleted: boolean | undefined, applicationTrackingType: Client.OeiApplicationTrackingTypeEnum | undefined): Promise<Client.GetPublicOeiApplicationsTrackingResponseDto> {
    const client = createOeiApplicationsClient();

    const request = new Client.GetPublicOeiApplicationsTrackingRequestDto({
        isCompleted: isCompleted ?? false,
        applicationTrackingType: applicationTrackingType,
    });

    const response = await simpleCall(() => client.getPublicOeiApplicationsTracking(request));
    return response;
}
