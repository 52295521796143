// framework
import NopimsPageLayout from "../../shell/NopimsPageLayout";
import { useDispatch } from "react-redux";
import * as Actions from "../redux/actions";
import { useEffect, useState } from "react";
import { StatusMessagesDto } from "../../../api/Client";
import StatusMessagesAlertsView from "../../../common/alerts/StatusMessagesAlertsView";
// views
import BasketView from "./BasketView";
import SubmitView from "../submit/SubmitView";
import { useNopimsBasket } from "../redux/reducers";

export default function Controller(): React.JSX.Element {
    const nopimsBasketModel = useNopimsBasket();
    const dispatch = useDispatch();

    const [showSubmit, setShowSubmit] = useState(false);
    const [statusMessages, setStatusMessages] = useState<StatusMessagesDto | undefined>();

    useEffect(() => {
        if (nopimsBasketModel.items.length > 0) {
            setStatusMessages(undefined);
        }
    }, [nopimsBasketModel]); // eslint-disable-line react-hooks/exhaustive-deps

    function onShowSubmitBasket(): void {
        setStatusMessages(undefined);
        setShowSubmit(true);
    }
    function onCancelSubmitBasket(): void {
        setShowSubmit(false);
    }
    function onSubmitComplete(statusMessages: StatusMessagesDto): void {
        setShowSubmit(false);
        setStatusMessages(statusMessages);
        dispatch(Actions.initialise());
    }

    return (
        <NopimsPageLayout title={["Basket"]}>
            {statusMessages && <StatusMessagesAlertsView statusMessages={statusMessages} />}
            {showSubmit ? <SubmitView onSubmitComplete={onSubmitComplete} onCancel={onCancelSubmitBasket} /> : <BasketView onShowSubmitBasket={onShowSubmitBasket} />}
        </NopimsPageLayout>
    );
}
