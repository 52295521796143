// framework
import * as Validation from "../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    if (Validation.isNullOrWhiteSpace(vm.feedback.customerFirstName)) {
        v.addError("CreateFeedback.CustomerFirstName", "First Name is required.");
    }

    if (Validation.isNullOrWhiteSpace(vm.feedback.customerLastName)) {
        v.addError("CreateFeedback.CustomerLastName", "Last Name is required.");
    }

    if (Validation.isNullOrWhiteSpace(vm.feedback.customerContactNumber)) {
        v.addError("CreateFeedback.CustomerContactNumber", "Contact Number is required.");
    }

    if (Validation.isNullOrWhiteSpace(vm.feedback.customerEmailAddress)) {
        v.addError("CreateFeedback.CustomerEmailAddress", "Email is required.");
    } else if (!Validation.isValidEmail(vm.feedback.customerEmailAddress!)) {
        v.addError("CreateFeedback.CustomerEmailAddress", "Email is not valid.");
    }

    if (!vm.feedback.feedbackType) {
        v.addError("CreateFeedback.FeedbackType", "Request Type is required.");
    }

    if (vm.feedback.feedbackType?.isDataFeedbackType && !vm.feedback.nopimsActivityType) {
        v.addError("CreateFeedback.NopimsActivityType", "Activity Type is required.");
    }

    if (vm.feedback.nopimsActivityType && !vm.feedback.activity) {
        v.addError("CreateFeedback.Activity", "Activity Name is required.");
    }

    if (vm.feedback.activity && !vm.feedback.dataMonitoringItemType) {
        v.addError("CreateFeedback.DataMonitoringItemType", "Data Type is required.");
    }

    if (Validation.isNullOrWhiteSpace(vm.feedback.customerComments)) {
        v.addError("CreateFeedback.CustomerComments", "Comments are required.");
    }

    return v;
}
