import { useSelector } from "react-redux";
import * as Client from "../../api/Client";
import { IRootReduxState } from "../../redux/rootReducer";
import { IShellState } from "../../shell/redux/shellReducers";

export function useIsDirty(): boolean {
    const state = useShellState();
    return state.dirtyCount > 0;
}

export function useHelpUrl(): string | undefined {
    const state = useShellState();
    return state.helpUrl;
}

export function useUserToken(): string | undefined {
    const state = useShellState();
    return state.userToken;
}

export function usePortalLink(name: Client.ExternalLinkEnum): string | undefined {
    const state = useShellState();
    const portalLinks = state.portalLinks?.links;
    return portalLinks ? portalLinks.filter((l) => l.name === name)[0].url : undefined;
}

export function useFeatureSwitch(): FeatureSwitch {
    const state = useShellState();
    const featureSwitches = state.featureSwitches?.featureSwitches;
    return new FeatureSwitch(featureSwitches);
}

export class FeatureSwitch {
    constructor(featureSwitches: Array<Client.NeatsFeatureSwitchEnum> | undefined) {
        this._featureSwitches = featureSwitches;
    }

    private _featureSwitches: Array<Client.NeatsFeatureSwitchEnum> | undefined;

    public check(featureSwitch: Client.NeatsFeatureSwitchEnum): boolean {
        if (!this._featureSwitches) return false;
        return this._featureSwitches.some((fs) => fs === featureSwitch);
    }
}

function useShellState(): IShellState {
    // don't expose this externally as it leaks too much internals of the shell
    // use this as a helper for exported functions
    return useSelector((state: IRootReduxState) => state.shell);
}
