// framework
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
// shell
import NopimsPageLayout from "../shell/NopimsPageLayout";
import { nopimsLhsNavItemEnum } from "../shell/NopimsLhsNav";
// common
import ODataAction from "../../common/odata/ODataAction";
import { useUserToken } from "../../common/shell/shellHelper";
import SelectedItems from "../../common/selectedItems/SelectedItems";
import SelectedItemsHelper from "../../common/selectedItems/SelectedItemsHelper";
// models
import { IWellSearchDataRow } from "./wellSearch/models";
// Controller
import WellSearchController from "./wellSearch/Controller";
import ReportsController from "./reports/Controller";
import SlideAndResiduesController from "./slideAndResidues/Controller";
import CoreSampleController from "./coreLibrary/Controller";

export default function Controller(): React.JSX.Element {
    const userToken = useUserToken();

    const [selectionVersion, setSelectionVersion] = useState<number>(1);
    const [selectedItems, setSelectedItems] = useState<Array<IWellSearchDataRow>>([]);
    const selectedItemsHelper = new SelectedItemsHelper<IWellSearchDataRow>(
        selectedItems,
        setSelectedItems,
        (item1: IWellSearchDataRow, item2: IWellSearchDataRow) => item1.BoreholeId === item2.BoreholeId
    );

    const [tabKey, setTabKey] = useState<string | null>("WellSearch");

    return (
        <NopimsPageLayout title={["Wells Search"]} lhsNavItem={nopimsLhsNavItemEnum.Wells} showGuidance={true}>
            <Tabs onSelect={(k) => setTabKey(k)}>
                <Tab title="Wells Search" eventKey="WellSearch">
                    <WellSearchController selectedItems={selectedItems} onAdd={(items) => selectedItemsHelper.TryAddAll(items)} onRemove={(item) => selectedItemsHelper.TryRemove(item)} />
                </Tab>
                <Tab title="Reports" eventKey="Reports" disabled={selectedItems.length === 0}>
                    <ReportsController selectionVersion={selectionVersion} />
                </Tab>
                <Tab title="Core Samples" eventKey="CoreSamples" disabled={selectedItems.length === 0}>
                    <CoreSampleController selectionVersion={selectionVersion} />
                </Tab>
                <Tab title="Slides & Residues" eventKey="Slides&Residues" disabled={selectedItems.length === 0}>
                    <SlideAndResiduesController selectionVersion={selectionVersion} />
                </Tab>
            </Tabs>
            <SelectedItems
                title="Borehole"
                showContainer={true}
                items={selectedItems}
                getItemTitle={(i) => i.Ubhi + " " + i.BoreholeName}
                onItemRemoved={(i) => selectedItemsHelper.TryRemove(i)}
                onItemsCleared={() => selectedItemsHelper.Clear()}
            />

            {tabKey === "WellSearch" && (
                <p className="mt-3">
                    Borehole status values published in NOPIMS have been populated based on information from various sources. Users are advised to undertake their own due diligence to determine
                    current borehole status, please refer to the Disclaimer for more detail.
                </p>
            )}

            {userToken && (
                <ODataAction
                    url="odata/v1/public/nopims/well/PublicNopimsWells/SetBoreholeSelections"
                    payload={{ userToken: userToken, boreholeIds: selectedItems.map((i) => i.BoreholeId) }}
                    onActioned={() => setSelectionVersion(selectionVersion + 1)}
                />
            )}
        </NopimsPageLayout>
    );
}
