// framework
import { useState } from "react";
import { DashIcon } from "@primer/octicons-react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// api
import * as Client from "../../../../api/Client";
// common
import TitleLink from "../../../../common/links/TitleLinkView";
import * as GlobalHelpers from "../../../../common/GlobalHelpers";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import * as OpenTitleHelper from "../../../../open/title/openTitleHelper";
import CopyLink from "../../../../common/links/CopyLinkView";
import * as SecureFileDownloadLinkControl from "../../../../common/secureFileDownload/SecureFileDownloadLinkControl";

function StorageFormationsListView(props: { storageFormations: Client.GetPublicStorageFormationListItemDto[] }) {
    const initialGridState = {
        sort: [{ field: "registeredDate", dir: "desc" }],
        skip: 0,
        take: 10,
    } as State;
    const [gridState, setGridState] = useState(initialGridState);

    function documentDownload(fileId: number | undefined, titleNumber: string, applicationId: string) {
        if (!fileId) {
            return <DashIcon size="small" />;
        }

        // titleNumber will have a value when there is a file associated
        const uri = OpenTitleHelper.generateDownloadMemorialDocumentUrl(titleNumber!, applicationId);

        return (
            <span>
                <SecureFileDownloadLinkControl.LinkControl className="me-1" fileId={fileId} title="Click to download the document." />
                <CopyLink href={uri} />
            </span>
        );
    }

    // view
    return (
        <>
            <div className="my-4">
                In accordance with section 315 of the OPGGS Act, the responsible Commonwealth Minister is to maintain a Register of Identified Greenhouse Gas Storage Formations.
            </div>

            <Grid
                pageable
                sortable
                resizable
                navigatable
                data={process(props.storageFormations, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="titleNumber"
                    title="Title"
                    width={120}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.storageFormations)}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <TitleLink titleId={props.dataItem.titleId} titleNumber={props.dataItem.titleNumber} />
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="titleType" title="Title Type" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.storageFormations)} />
                <GridColumn field="storageFormationName" title="Storage Formation" width={300} filter="text" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="titleholders" title="Titleholders" width={500} filter="text" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="storageFormationStatus" title="Decision" width={160} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, props.storageFormations)} />
                <GridColumn field="mapSheet" title="Map Sheet" width={200} filter="text" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="graticularBlocks" title="Graticular Blocks" width={200} filter="text" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="implementedDate" title="Implemented Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={220} filter="date" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="registeredDate" title="Registered Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={220} filter="date" columnMenu={createGridColumnFilterMenu} />
                <GridColumn
                    field="fileId"
                    title="Document"
                    width={300}
                    cell={(props) => <CustomGridCell gridCellProps={props}>{documentDownload(props.dataItem.fileId, props.dataItem.titleNumber, props.dataItem.applicationId)}</CustomGridCell>}
                />
            </Grid>
        </>
    );
}

export default StorageFormationsListView;
