// framework
import React from "react";
import { connect } from "react-redux";
// shell
import ConfigurationController from "./ConfigurationController";
// header
import SupportedBrowserBanner from "./SupportedBrowserBanner";
import TopNav from "./TopNav";
// body
import BusyOverlayController from "./BusyOverlayController";
// toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./react-toastify-overrides.css";
// footer
import Footer from "./Footer";
// error
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./error/ErrorControl";
// session
import SessionController from "./SessionController";
import { IShellState } from "../redux/shellReducers";
import UserTokenController from "./UserTokenController";

function Layout(props: { children: React.ReactNode; state: IShellState }) {
    const children = props.children as React.ReactNode;
    const pageTitle = props.state.pageTitle;
    const isDirty = props.state.dirtyCount > 0;
    const showPageHeader = props.state.showPageHeader;
    const includePadding = props.state.includePadding;

    const showPageTitle = pageTitle !== null && pageTitle !== "";

    let title = showPageTitle ? pageTitle + " | NEATS" : "NEATS Public Portal";
    if (isDirty) title = "*" + title;
    document.title = title;

    function childrenWithStaticReferenceData(): React.ReactNode {
        return <ConfigurationController>{children}</ConfigurationController>;
    }

    function layoutWithPadding(): React.ReactNode {
        return (
            <div className="container-fluid px-4 py-2 my-2">
                {showPageTitle && showPageHeader && <h1>{pageTitle}</h1>}
                {childrenWithStaticReferenceData()}
            </div>
        );
    }

    function layoutWithoutPadding(): React.ReactNode {
        return (
            <div className="container-fluid p-0 mb-2">
                {showPageTitle && showPageHeader && <h1>{pageTitle}</h1>}
                {childrenWithStaticReferenceData()}
            </div>
        );
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <SessionController />
            <UserTokenController />
            <BusyOverlayController />
            <ToastContainer
                position="top-right"
                autoClose={15000} // 15 seconds to allow time for screenreaders
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false} // allow user to copy/paste content, they can still click X to close
                pauseOnFocusLoss={true} // stops timer when window has lost focus
                draggable={false}
                pauseOnHover={true} // allows user to copy/paste content
                theme="colored" // this generally matches bootstrappy themes
            />
            <header>
                <TopNav />
                <SupportedBrowserBanner />
            </header>
            <main className="mb-2">{includePadding ? layoutWithPadding() : layoutWithoutPadding()}</main>
            <footer>
                <Footer />
            </footer>
        </ErrorBoundary>
    );
}

// connect redux
export default connect((state: any) => ({ state: state.shell }))(Layout);
