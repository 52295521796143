// framework
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BsBasket3Fill } from "react-icons/bs";
// kendo
import { DataResult, State } from "@progress/kendo-data-query";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridDataStateChangeEvent, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { FilterChangeEvent } from "@progress/kendo-react-data-tools";
// common
import ODataReader from "../../../common/odata/ODataReader";
import { useUserToken } from "../../../common/shell/shellHelper";
import GridFilter from "../../../common/kendoGrid/GridFilter";
import { getTextFilter } from "../../../common/kendoGrid/GridFilterOptions";
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";
import * as GlobalHelpers from "../../../common/GlobalHelpers";
import DownloadLinkView from "../../../common/externalLink/DownloadLinkView";
import ExternalLinkView from "../../../common/externalLink/ExternalLinkView";
import { TextBoxFilter } from "../../../common/kendoGrid/GridFilterTextBoxFilter";
// basket
import * as Actions from "../../basket/redux/actions";
import { useNopimsBasket } from "../../basket/redux/reducers";
import { NopimsBasketItemModel } from "../../basket/redux/model";
// models
import { IReprocessingSurveyDataDataRow } from "./models";

interface IProps {
    selectionVersion: number;
}

export default function Controller(props: IProps) {
    const userToken = useUserToken();
    const nopimsBasketModel = useNopimsBasket();
    const dispatch = useDispatch();

    const [pageSize, setPageSize] = useState(10);
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
            {
                field: "ReprocessingName",
                operator: "contains",
                value: "",
            },
        ],
    };

    const [gridState, setGridDataState] = useState<State>({
        sort: [
            { field: "ReprocessingName", dir: "asc" },
            { field: "ReprocessingProjectName", dir: "asc" },
            { field: "ItemId", dir: "asc" },
        ],
        take: pageSize,
        skip: 0,
        filter: initialFilter,
    });

    const [filter, setFilter] = useState<CompositeFilterDescriptor>(initialFilter);
    function onFilterChange(e: FilterChangeEvent): void {
        setFilter(e.filter);
        setGridDataState({ ...gridState, filter: e.filter });
    }

    const [data, setData] = useState<DataResult>({ data: [], total: 0 });
    function onDataChange(data: DataResult): void {
        setData(data);
    }

    function mapDataRow(dataRow: any): IReprocessingSurveyDataDataRow {
        return dataRow;
    }

    // ensure we don't get stuck on an empty grid page
    useEffect(() => {
        if (data.data.length === 0 && data.total > 0 && gridState && gridState.skip! > 0) setGridDataState({ ...gridState, skip: 0 });
    }, [data, gridState]);

    function onBasketAddAll(): void {
        dispatch(
            Actions.itemsAdded(
                data.data.map((item) => new NopimsBasketItemModel(item.DataDescription ?? ``, undefined, item.SurveyDataId, undefined, undefined, item.ReprocessingProjectId, item.ItemId))
            )
        );
    }

    function onBasketAdd(item: IReprocessingSurveyDataDataRow): void {
        dispatch(Actions.itemsAdded([new NopimsBasketItemModel(item.DataDescription ?? ``, undefined, item.SurveyDataId, undefined, undefined, item.ReprocessingProjectId, item.ItemId)]));
    }

    // return
    return (
        <>
            <GridFilter
                value={filter}
                onChange={onFilterChange}
                fields={[
                    { name: "AttachmentArchiveFileName", label: "Download", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "ReprocessingIdentifier", label: "Reprocessing ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "ReprocessingName", label: "Reprocessing", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "ReprocessingProjectIdentifier", label: "Project ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "ReprocessingProjectName", label: "Project", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "DataDescription", label: "Data Description", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "DataType ", label: "Data Type", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "TapeNumber ", label: "Tape Number", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "FirstShotPoint ", label: "First Shot Point", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "LastShotPoint ", label: "Last Shot Point", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "ItemId ", label: "Item ID", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "LineName ", label: "Line Name", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "DataFormat", label: "Data Format", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "MediaType", label: "Media Type", filter: TextBoxFilter, operators: getTextFilter(true) },
                ]}
            />
            {userToken && (
                <ODataReader
                    url={`odata/v1/public/nopims/reprocessing/PublicNopimsReprocessings/GetSurveyDataForSelection(userToken=${userToken})`}
                    showContentAsBusyElementId="ReprocessingSurveyDataSearchResultsGrid"
                    dataState={gridState}
                    onDataReceived={onDataChange}
                    mapDataRow={mapDataRow}
                    changeToken={props.selectionVersion}
                />
            )}
            <div className="mb-4">
                <Grid
                    id="ReprocessingSurveyDataSearchResultsGrid"
                    sortable
                    pageable={{ pageSizes: [10, 20, 50, 100], pageSizeValue: pageSize, responsive: false }}
                    onPageChange={(e: GridPageChangeEvent) => {
                        setPageSize(e.page.take);
                        setGridDataState({ ...gridState, take: e.page.take, skip: e.page.skip });
                    }}
                    resizable
                    navigatable
                    {...gridState}
                    data={data}
                    onDataStateChange={(e: GridDataStateChangeEvent) => setGridDataState(e.dataState)}
                >
                    <GridColumn
                        width={60}
                        headerCell={() => (
                            <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => onBasketAddAll()} title="Click to add all Survey Datas on this page to the Basket.">
                                <BsBasket3Fill size={20} />
                            </button>
                        )}
                        title="Basket"
                        cell={(gridCellProps) => (
                            <CustomGridCell gridCellProps={gridCellProps} className={gridCellProps.className + " py-1"} style={gridCellProps.style}>
                                {nopimsBasketModel.items.every((i) => i.surveyDataId !== gridCellProps.dataItem.SurveyDataId) && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={() => onBasketAdd(gridCellProps.dataItem)}
                                        title="Click to add this Survey Data to the Basket."
                                    >
                                        <BsBasket3Fill size={20} />
                                    </button>
                                )}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="AttachmentManifestUrl"
                        title="Info"
                        width={90}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AttachmentManifestUrl && <ExternalLinkView href={props.dataItem.AttachmentManifestUrl}>Listing</ExternalLinkView>}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="AttachmentArchiveFileName"
                        title="Download"
                        width={160}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AttachmentArchiveUrl && <DownloadLinkView href={props.dataItem.AttachmentArchiveUrl}>{props.dataItem.AttachmentArchiveFileName}</DownloadLinkView>}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="AttachmentArchiveFileSize"
                        title="File Size"
                        width={140}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AttachmentArchiveFileSize && GlobalHelpers.convertBytesToString(props.dataItem.AttachmentArchiveFileSize)}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="ReprocessingIdentifier" title="Reprocessing ID" width={170} />
                    <GridColumn field="ReprocessingName" title="Reprocessing" width={350} />
                    <GridColumn field="ReprocessingProjectIdentifier" title="Project ID" width={150} />
                    <GridColumn field="ReprocessingProjectName" title="Project" width={350} />
                    <GridColumn field="DataDescription" title="Data Description" width={600} filter="text" />
                    <GridColumn field="DataType" title="Data Type" width={200} filter="text" />
                    <GridColumn field="TapeNumber" title="Tape Number" width={200} filter="text" />
                    <GridColumn field="FirstShotPoint" title="First Shot Point" width={150} filter="text" />
                    <GridColumn field="LastShotPoint" title="Last Shot Point" width={150} filter="text" />
                    <GridColumn field="ItemId" title="Item ID" width={200} filter="text" />
                    <GridColumn field="LineName" title="Line Name" width={250} filter="text" />
                    <GridColumn field="DataFormat" title="Data Format" width={140} filter="text" />
                    <GridColumn field="MediaType" title="Media Type" width={140} filter="text" />
                </Grid>
            </div>
        </>
    );
}
