// framework
import React, { useEffect, useState } from "react";
// kendo
import KendoComboBox from "../../../common/kendo/KendoComboBox";
import KendoDropDownList from "../../../common/kendo/KendoDropDownList";
import KendoLargeComboBox from "../../../common/kendo/KendoLargeComboBox";
// api
import * as Client from "../../../api/Client";
// common
import * as Forms from "../../../common/forms/BootstrapForms";
import { IValidationModel } from "../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../common/validation/ValidationMessageView";
// models
import { IFeedbackViewModel, IRootViewModel } from "../models/models";

interface IProps {
    vm: IRootViewModel;
    setVm: (vm: IRootViewModel) => void;
    validation: IValidationModel;
    onReset: () => void;
    onSubmit: () => void;
}

export default function FeedbackCreateView(props: IProps): React.JSX.Element {
    const { vm, setVm, validation, onReset, onSubmit } = props;

    const [state, setState] = useState<IFeedbackViewModel>(vm.feedback);
    const honorifics = vm.referenceData.honorifics;
    const feedbackTypes = vm.referenceData.feedbackTypes;
    const nopimsActivityTypes = vm.referenceData.nopimsActivityTypes;
    const dataFeedbackActivities = vm.referenceData.activities;
    const dataMonitoringItemTypes = vm.referenceData.dataMonitoringItemTypes;

    useEffect(() => {
        setState(vm.feedback);
    }, [vm.feedback]);

    function updateViewModel() {
        setVm(vm.refreshFeedbackDetails({ ...state }));
    }

    function updateStateAndViewModel(state: IFeedbackViewModel) {
        setState(state);
        setVm(vm.refreshFeedbackDetails({ ...state }));
    }

    // cascading reference data
    const [filteredActivites, setFilteredActivites] = useState<Client.GetCreatePublicFeedbackReferenceDataResponseActivityDto[]>([]);
    useEffect(() => {
        setFilteredActivites(state.nopimsActivityType ? dataFeedbackActivities.filter((n) => n.nopimsActivityTypeId === state.nopimsActivityType!.id) : []);
    }, [dataFeedbackActivities, state.nopimsActivityType]);

    const [filteredDataMonitoringItemTypes, setFilteredDataMonitoringItemTypes] = useState<Client.GetCreatePublicFeedbackReferenceDataResponseDataMonitoringItemTypeDto[]>([]);
    useEffect(() => {
        setFilteredDataMonitoringItemTypes(
            state.nopimsActivityType && state.activity
                ? dataMonitoringItemTypes.filter((n) => n.nopimsActivityTypeId === state.nopimsActivityType!.id && n.surveyAcquisitionTypeId === state.activity!.surveyAcquisitionTypeId)
                : []
        );
    }, [dataMonitoringItemTypes, state.nopimsActivityType, state.activity]);

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Contact Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.Label htmlFor="honorificField">Title</Forms.Label>
                                <KendoComboBox
                                    id="honorificField"
                                    data={honorifics}
                                    keyField="id"
                                    textField="name"
                                    filterable={false}
                                    suggest={true}
                                    value={state.honorific}
                                    onChange={(value) => updateStateAndViewModel({ ...state, honorific: value })}
                                    onBlur={updateViewModel}
                                />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerFirstNameField"
                                    label="First Name"
                                    value={state.customerFirstName}
                                    valid={validation.applyValidity("CreateFeedback.CustomerFirstName")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerFirstName: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateFeedback.CustomerFirstName" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerLastNameField"
                                    label="Last Name"
                                    value={state.customerLastName}
                                    valid={validation.applyValidity("CreateFeedback.CustomerLastName")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerLastName: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateFeedback.CustomerLastName" validationModel={validation} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerCompanyNameField"
                                    label="Company"
                                    value={state.customerCompanyName}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerCompanyName: value })}
                                    onBlur={updateViewModel}
                                />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerContactNumberField"
                                    label="Contact Number"
                                    value={state.customerContactNumber}
                                    valid={validation.applyValidity("CreateFeedback.CustomerContactNumber")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerContactNumber: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateFeedback.CustomerContactNumber" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerEmailAddressField"
                                    label="Email"
                                    value={state.customerEmailAddress}
                                    valid={validation.applyValidity("CreateFeedback.CustomerEmailAddress")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerEmailAddress: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateFeedback.CustomerEmailAddress" validationModel={validation} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <h2 className="card-title">Request Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.Label htmlFor="feedbackTypeField">Request Type</Forms.Label>
                                <KendoDropDownList
                                    id="feedbackTypeField"
                                    data={feedbackTypes}
                                    keyField="id"
                                    textField="name"
                                    value={state.feedbackType}
                                    valid={validation.applyValidity("CreateFeedback.FeedbackType")}
                                    onChange={(value) =>
                                        updateStateAndViewModel({
                                            ...state,
                                            feedbackType: value,
                                            nopimsActivityType: undefined,
                                            activity: undefined,
                                            dataMonitoringItemType: undefined,
                                        })
                                    }
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateFeedback.FeedbackType" validationModel={validation} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    {state.feedbackType?.isDataFeedbackType && (
                        <>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.ThirdColumn>
                                        <Forms.Label htmlFor="nopimsActivityTypeField">Activity Type</Forms.Label>
                                        <KendoDropDownList
                                            id="nopimsActivityTypeField"
                                            data={nopimsActivityTypes}
                                            keyField="id"
                                            textField="name"
                                            value={state.nopimsActivityType}
                                            valid={validation.applyValidity("CreateFeedback.NopimsActivityType")}
                                            onChange={(value) => updateStateAndViewModel({ ...state, nopimsActivityType: value, activity: undefined, dataMonitoringItemType: undefined })}
                                            onBlur={updateViewModel}
                                        />
                                        <ValidationMessageView name="CreateFeedback.NopimsActivityType" validationModel={validation} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.Label htmlFor="activityField">Activity Name</Forms.Label>
                                        <KendoLargeComboBox
                                            id="activityField"
                                            data={filteredActivites}
                                            keyField="wellId"
                                            textField="name"
                                            filterable={true}
                                            suggest={true}
                                            value={state.activity}
                                            disabled={!state.nopimsActivityType}
                                            valid={validation.applyValidity("CreateFeedback.Activity")}
                                            onChange={(value) => updateStateAndViewModel({ ...state, activity: value, dataMonitoringItemType: undefined })}
                                            onBlur={updateViewModel}
                                        />
                                        <ValidationMessageView name="CreateFeedback.Activity" validationModel={validation} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.Label htmlFor="dataMonitoringItemTypeField">Data Type</Forms.Label>
                                        <KendoComboBox
                                            id="dataMonitoringItemTypeField"
                                            data={filteredDataMonitoringItemTypes}
                                            keyField="id"
                                            textField="name"
                                            filterable={true}
                                            suggest={true}
                                            value={state.dataMonitoringItemType}
                                            disabled={!state.activity}
                                            valid={validation.applyValidity("CreateFeedback.DataMonitoringItemType")}
                                            onChange={(value) => updateStateAndViewModel({ ...state, dataMonitoringItemType: value })}
                                            onBlur={updateViewModel}
                                        />
                                        <ValidationMessageView name="CreateFeedback.DataMonitoringItemType" validationModel={validation} />
                                    </Forms.ThirdColumn>
                                </Forms.Row>
                            </Forms.Group>
                        </>
                    )}
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.FullColumn>
                                <Forms.TextArea
                                    id="customerCommentsField"
                                    label="Comments"
                                    value={state.customerComments}
                                    valid={validation.applyValidity("CreateFeedback.CustomerComments")}
                                    maxLength={4000}
                                    onChange={(value) => setState({ ...state, customerComments: value })}
                                    onBlur={updateViewModel}
                                    rows={5}
                                />
                                <ValidationMessageView name="CreateFeedback.CustomerComments" validationModel={validation} />
                            </Forms.FullColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <div className="mt-3">
                        <button className="btn btn-primary me-2" type="button" onClick={onSubmit}>
                            Submit
                        </button>
                        <button className="btn btn-primary me-2" type="button" onClick={onReset}>
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
