// kendo
import { GridCellProps, GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

function CustomGridCell(props: { gridCellProps: GridCellProps; style?: React.CSSProperties | undefined; children: React.ReactNode; className?: string | undefined }) {
    const gridCellProps = props.gridCellProps;
    const navigationAttributes = useTableKeyboardNavigation(gridCellProps.id);

    // see https://www.telerik.com/kendo-react-ui/components/grid/cells/#toc-customization for details
    // see https://feedback.telerik.com/kendo-react-ui/1526388-kendoreact-easier-implementation-of-custom-grid-cells for a Feedback Portal request for a GridCell template so that this isn't necessary!
    return (
        <td
            colSpan={gridCellProps.colSpan}
            role="gridcell"
            aria-colindex={gridCellProps.ariaColumnIndex}
            aria-selected={gridCellProps.isSelected}
            {...{ [GRID_COL_INDEX_ATTRIBUTE]: gridCellProps.columnIndex }}
            {...navigationAttributes}
            style={props.style}
            className={props.className}
        >
            {props.children}
        </td>
    );
}

export default CustomGridCell;
