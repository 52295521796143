// framework
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BsBasket3Fill } from "react-icons/bs";
// kendo
import { DataResult, State } from "@progress/kendo-data-query";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridDataStateChangeEvent, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { FilterChangeEvent, DateFilter } from "@progress/kendo-react-data-tools";
// common
import ODataReader from "../../../common/odata/ODataReader";
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";
import * as GlobalHelpers from "../../../common/GlobalHelpers";
import ExternalLinkView from "../../../common/externalLink/ExternalLinkView";
import DownloadLinkView from "../../../common/externalLink/DownloadLinkView";
import GridFilter from "../../../common/kendoGrid/GridFilter";
import { getDateFilter, getTextFilter } from "../../../common/kendoGrid/GridFilterOptions";
import SimpleAlert from "../../../common/simpleAlerts/SimpleAlertView";
import { TextBoxFilter } from "../../../common/kendoGrid/GridFilterTextBoxFilter";
// basket
import * as Actions from "../../basket/redux/actions";
import { useNopimsBasket } from "../../basket/redux/reducers";
import { NopimsBasketItemModel } from "../../basket/redux/model";
// models
import { IDocumentSearchDataRow } from "./models";
import CopyODataLinkView from "../../../common/odata/CopyODataLinkView";

export default function DocumentSearchController() {
    const nopimsBasketModel = useNopimsBasket();
    const dispatch = useDispatch();

    const [pageSize, setPageSize] = useState(10);
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
            {
                field: "ActivityName",
                operator: "contains",
                value: "",
            },
        ],
    };

    const [gridState, setGridDataState] = useState<State>({
        sort: [
            { field: "ReleasedDate", dir: "desc" },
            { field: "DocumentType", dir: "asc" },
            { field: "DataDescription", dir: "asc" },
        ],
        take: pageSize,
        skip: 0,
        filter: initialFilter,
    });

    const [filter, setFilter] = useState<CompositeFilterDescriptor>(initialFilter);
    function onFilterChange(e: FilterChangeEvent): void {
        setFilter(e.filter);
        setGridDataState({ ...gridState, filter: e.filter });
    }

    const [data, setData] = useState<DataResult>({ data: [], total: 0 });
    function onDataChange(data: DataResult): void {
        setData(data);
    }

    function mapDataRow(dataRow: any): IDocumentSearchDataRow {
        return {
            ReleasedDate: new Date(dataRow.ReleasedDate),
            DocumentType: dataRow.DocumentType,
            DataDescription: dataRow.DataDescription,
            AttachmentManifestUrl: dataRow.AttachmentManifestUrl,
            AttachmentArchiveUrl: dataRow.AttachmentArchiveUrl,
            AttachmentArchiveFileName: dataRow.AttachmentArchiveFileName,
            AttachmentArchiveFileSize: dataRow.AttachmentArchiveFileSize,
            ItemId: dataRow.ItemId,
            ActivityType: dataRow.ActivityType,
            ActivityId: dataRow.ActivityId,
            ActivityName: dataRow.ActivityName,
            Basins: dataRow.Basins,
            ReportId: dataRow.ReportId,
            SurveyDataId: dataRow.SurveyDataId,
            BoreholeId: dataRow.BoreholeId,
            AcquisitionId: dataRow.AcquisitionId,
            ProjectId: dataRow.ProjectId,
        };
    }

    // ensure we don't get stuck on an empty grid page
    useEffect(() => {
        if (data.data.length === 0 && data.total > 0 && gridState && gridState.skip! > 0) setGridDataState({ ...gridState, skip: 0 });
    }, [data, gridState]);

    function onBasketAddAll(): void {
        dispatch(
            Actions.itemsAdded(
                data.data.map(
                    (i: IDocumentSearchDataRow) =>
                        new NopimsBasketItemModel(
                            i.DataDescription,
                            i.ReportId ?? undefined,
                            i.SurveyDataId ?? undefined,
                            i.BoreholeId ?? undefined,
                            i.AcquisitionId ?? undefined,
                            i.ProjectId ?? undefined,
                            i.ItemId
                        )
                )
            )
        );
    }

    function onBasketAdd(i: IDocumentSearchDataRow): void {
        dispatch(
            Actions.itemsAdded([
                new NopimsBasketItemModel(
                    i.DataDescription,
                    i.ReportId ?? undefined,
                    i.SurveyDataId ?? undefined,
                    i.BoreholeId ?? undefined,
                    i.AcquisitionId ?? undefined,
                    i.ProjectId ?? undefined,
                    i.ItemId
                ),
            ])
        );
    }

    // return
    return (
        <>
            <div className="row">
                <div className="col">
                    <GridFilter
                        value={filter}
                        onChange={onFilterChange}
                        fields={[
                            { name: "AttachmentArchiveFileName", label: "Download", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "DataDescription", label: "Data Description", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "ReleasedDate", label: "Released Date", filter: DateFilter, operators: getDateFilter(false) },
                            { name: "DocumentType", label: "Document Type", filter: TextBoxFilter, operators: getTextFilter(false) },
                            { name: "ItemId", label: "Item ID", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "ActivityType", label: "Activity Type", filter: TextBoxFilter, operators: getTextFilter(false) },
                            { name: "ActivityId", label: "Activity ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                            { name: "ActivityName", label: "Activity Name", filter: TextBoxFilter, operators: getTextFilter(false) },
                            { name: "Basins", label: "Basins", filter: TextBoxFilter, operators: getTextFilter(true) },
                        ]}
                    />
                </div>
                <div className="col-auto">
                    <div className="mt-2 float-right">
                        <CopyODataLinkView title="Released Document OData" toolTip="Released Document" oDataPath="/odata/v1/public/nopims/releasedDocument/PublicNopimsReleasedDocument" />
                    </div>
                </div>
            </div>
            <ODataReader
                url="odata/v1/public/nopims/releasedDocument/PublicNopimsReleasedDocument"
                showContentAsBusyElementId="ReleasedDocumentSearchResultsGrid"
                dataState={gridState}
                onDataReceived={onDataChange}
                mapDataRow={mapDataRow}
            />
            <SimpleAlert message="The list below contains Documents that have been released within the last 12 months." alertType="information" />
            <div className="mb-4">
                <Grid
                    id="ReleasedDocumentSearchResultsGrid"
                    sortable
                    pageable={{ pageSizes: [10, 20, 50, 100], pageSizeValue: pageSize, responsive: false }}
                    onPageChange={(e: GridPageChangeEvent) => {
                        setPageSize(e.page.take);
                        setGridDataState({ ...gridState, take: e.page.take, skip: e.page.skip });
                    }}
                    resizable
                    navigatable
                    {...gridState}
                    data={data}
                    onDataStateChange={(e: GridDataStateChangeEvent) => setGridDataState(e.dataState)}
                >
                    <GridColumn
                        width={60}
                        headerCell={() => (
                            <button type="button" className="btn btn-outline-secondary btn-sm" title="Click to add all Documents on this page to the Basket." onClick={() => onBasketAddAll()}>
                                <BsBasket3Fill size={20} />
                            </button>
                        )}
                        title="Basket"
                        cell={(gridCellProps) => (
                            <CustomGridCell gridCellProps={gridCellProps} className={gridCellProps.className + " py-1"} style={gridCellProps.style}>
                                {nopimsBasketModel.items.every(
                                    (i) => (i.reportId && i.reportId !== gridCellProps.dataItem.ReportId) || (i.surveyDataId && i.surveyDataId !== gridCellProps.dataItem.SurveyDataId)
                                ) && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm"
                                        title="Click to add this Document to the Basket."
                                        onClick={() => onBasketAdd(gridCellProps.dataItem)}
                                    >
                                        <BsBasket3Fill size={20} />
                                    </button>
                                )}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="AttachmentManifestUrl"
                        title="Info"
                        width={90}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AttachmentManifestUrl && <ExternalLinkView href={props.dataItem.AttachmentManifestUrl}>Listing</ExternalLinkView>}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="AttachmentArchiveFileName"
                        title="Download"
                        width={160}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AttachmentArchiveUrl && <DownloadLinkView href={props.dataItem.AttachmentArchiveUrl}>{props.dataItem.AttachmentArchiveFileName}</DownloadLinkView>}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="AttachmentArchiveFileSize"
                        title="File Size"
                        width={140}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AttachmentArchiveFileSize && GlobalHelpers.convertBytesToString(props.dataItem.AttachmentArchiveFileSize)}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="DataDescription" title="Data Description" width={600} />
                    <GridColumn field="ReleasedDate" title="Released Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="DocumentType" title="Document Type" width={200} filter="text" />
                    <GridColumn field="ItemId" title="Item ID" width={150} />
                    <GridColumn field="ActivityType" title="Activity Type" width={200} />
                    <GridColumn field="ActivityId" title="Activity ID" width={150} />
                    <GridColumn field="ActivityName" title="Activity Name" width={400} />
                    <GridColumn field="Basins" title="Basins" width={350} />
                </Grid>
            </div>
        </>
    );
}
