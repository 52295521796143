// kendo
import { TextBox } from "@progress/kendo-react-inputs";

// NOTE: Solution suggested by Kendo, not ideal. Feature request for allowing a placeholder set for all Filters is below.
// https://feedback.telerik.com/kendo-react-ui/1664642-external-filter-props-placeholder
// NOTE2: Using a InputPropsContext with the GridFilter would also be a better solution, but that cannot be used due to a bug, see details below.
// https://github.com/telerik/kendo-react/issues/2525

export const TextBoxFilter = (props: any) => {
    const onChange = (event: any) => {
        props.onFilterChange({
            nextFilter: {
                ...props.filter,
                value: event.target.value,
            },
        });
    };

    return <TextBox className="k-textbox" value={props.filter.value || ""} onChange={onChange} placeholder="filter" />;
};
