import { logError } from "../../common/LogHelper";
import { createFeedbackClient } from "../../api/clientFactory";
import { simpleCall } from "../../api/callHelper";
import * as Client from "../../api/Client";
import { IRootViewModel } from "./models/models";

export async function initialise(viewModel: IRootViewModel, setViewModel: (viewModel: IRootViewModel) => void): Promise<void> {
    let vm = viewModel;
    try {
        setViewModel((vm = vm.setBusy(true)));
        const client = createFeedbackClient();
        const request = new Client.GetCreatePublicFeedbackReferenceDataRequestDto({});
        const response = await simpleCall(() => client.getCreatePublicFeedbackReferenceData(request));
        setViewModel((vm = vm.refreshReferenceData(response)));
    } catch (e) {
        logError(e);
    } finally {
        setViewModel((vm = vm.setBusy(false)));
    }
}

export async function createPublicFeedbackCommand(viewModel: IRootViewModel, setViewModel: (viewModel: IRootViewModel) => void): Promise<void> {
    let vm = viewModel;
    try {
        setViewModel((vm = vm.setBusy(true)));
        const client = createFeedbackClient();
        const request = new Client.CreatePublicFeedbackRequestDto({
            userToken: vm.userToken,
            honorificId: vm.feedback.honorific?.id,
            customerFirstName: vm.feedback.customerFirstName!,
            customerLastName: vm.feedback.customerLastName!,
            customerCompanyName: vm.feedback.customerCompanyName,
            customerContactNumber: vm.feedback.customerContactNumber!,
            customerEmailAddress: vm.feedback.customerEmailAddress!,
            feedbackTypeId: vm.feedback.feedbackType!.id,
            nopimsActivityTypeId: vm.feedback.nopimsActivityType?.id,
            activityId: vm.feedback.activity?.id,
            dataMonitoringItemTypeId: vm.feedback.dataMonitoringItemType?.id,
            customerComments: vm.feedback.customerComments!,
        });
        const response = await simpleCall(() => client.createPublicFeedback(request));
        setViewModel((vm = vm.refreshFeedbackCreateResponse(response)));
    } catch (e) {
        logError(e);
    } finally {
        setViewModel((vm = vm.setBusy(false)));
    }
}
