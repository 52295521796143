// framework
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BsBasket3Fill } from "react-icons/bs";
// kendo
import { DataResult, State } from "@progress/kendo-data-query";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridDataStateChangeEvent, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { FilterChangeEvent } from "@progress/kendo-react-data-tools";
// common
import ODataReader from "../../../common/odata/ODataReader";
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";
import { useUserToken } from "../../../common/shell/shellHelper";
import * as GlobalHelpers from "../../../common/GlobalHelpers";
import ExternalLinkView from "../../../common/externalLink/ExternalLinkView";
import DownloadLinkView from "../../../common/externalLink/DownloadLinkView";
import GridFilter from "../../../common/kendoGrid/GridFilter";
import { getTextFilter } from "../../../common/kendoGrid/GridFilterOptions";
import { TextBoxFilter } from "../../../common/kendoGrid/GridFilterTextBoxFilter";
// basket
import * as Actions from "../../basket/redux/actions";
import { useNopimsBasket } from "../../basket/redux/reducers";
import { NopimsBasketItemModel } from "../../basket/redux/model";
// models
import { IReportDataRow } from "./models";

interface IProps {
    selectionVersion: number;
}

export default function Controller(props: IProps) {
    const userToken = useUserToken();
    const nopimsBasketModel = useNopimsBasket();
    const dispatch = useDispatch();

    const [pageSize, setPageSize] = useState(10);
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
            {
                field: "SurveyName",
                operator: "contains",
                value: "",
            },
        ],
    };

    const [gridState, setGridDataState] = useState<State>({
        sort: [
            { field: "SurveyName", dir: "asc" },
            { field: "AcquisitionName", dir: "asc" },
            { field: "ReportTitle", dir: "asc" },
        ],
        take: pageSize,
        skip: 0,
        filter: initialFilter,
    });

    const [filter, setFilter] = useState<CompositeFilterDescriptor>(initialFilter);
    function onFilterChange(e: FilterChangeEvent): void {
        setFilter(e.filter);
        setGridDataState({ ...gridState, filter: e.filter });
    }

    const [data, setData] = useState<DataResult>({ data: [], total: 0 });
    function onDataChange(data: DataResult): void {
        setData(data);
    }

    function mapDataRow(dataRow: any): IReportDataRow {
        return dataRow;
    }

    // ensure we don't get stuck on an empty grid page
    useEffect(() => {
        if (data.data.length === 0 && data.total > 0 && gridState && gridState.skip! > 0) setGridDataState({ ...gridState, skip: 0 });
    }, [data, gridState]);

    function onBasketAddAll(): void {
        dispatch(
            Actions.itemsAdded(data.data.map((i: IReportDataRow) => new NopimsBasketItemModel(i.ReportTitle ?? ``, i.ReportId, undefined, undefined, i.AcquisitionId, undefined, i.ReportItemId)))
        );
    }

    function onBasketAdd(item: IReportDataRow): void {
        dispatch(Actions.itemsAdded([new NopimsBasketItemModel(item.ReportTitle ?? ``, item.ReportId, undefined, undefined, item.AcquisitionId, undefined, item.ReportItemId)]));
    }

    // return
    return (
        <>
            <GridFilter
                value={filter}
                onChange={onFilterChange}
                fields={[
                    { name: "AttachmentArchiveFileName", label: "Download", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "ReportTitle", label: "Data Description", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "ReportType", label: "Report Type", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "Company", label: "Company", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "TapeId", label: "Tape ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "ReportItemId", label: "Item ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "SurveyIdentifier", label: "Survey ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "SurveyName", label: "Survey", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "AcquisitionIdentifier", label: "Acquisition ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "AcquisitionName", label: "Acquisition", filter: TextBoxFilter, operators: getTextFilter(false) },
                ]}
            />
            {userToken && (
                <ODataReader
                    url={`odata/v1/public/nopims/survey/PublicNopimsSurvey/GetReportsForSelection(userToken=${userToken})`}
                    showContentAsBusyElementId="SurveyReportsSearchResultsGrid"
                    dataState={gridState}
                    onDataReceived={onDataChange}
                    mapDataRow={mapDataRow}
                    changeToken={props.selectionVersion}
                />
            )}
            <div className="mb-4">
                <Grid
                    id="SurveyReportsSearchResultsGrid"
                    sortable
                    pageable={{ pageSizes: [10, 20, 50, 100], pageSizeValue: pageSize, responsive: false }}
                    onPageChange={(e: GridPageChangeEvent) => {
                        setPageSize(e.page.take);
                        setGridDataState({ ...gridState, take: e.page.take, skip: e.page.skip });
                    }}
                    resizable
                    navigatable
                    {...gridState}
                    data={data}
                    onDataStateChange={(e: GridDataStateChangeEvent) => setGridDataState(e.dataState)}
                >
                    <GridColumn
                        width={60}
                        headerCell={() => (
                            <button type="button" className="btn btn-outline-secondary btn-sm" title={`Click to add all Reports on this page to the Basket.`} onClick={() => onBasketAddAll()}>
                                <BsBasket3Fill size={20} />
                            </button>
                        )}
                        title="Basket"
                        cell={(gridCellProps) => (
                            <CustomGridCell gridCellProps={gridCellProps} className={gridCellProps.className + " py-1"} style={gridCellProps.style}>
                                {nopimsBasketModel.items.every((i) => i.reportId !== gridCellProps.dataItem.ReportId) && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm"
                                        title="Click to add this Report to the Basket."
                                        onClick={() => onBasketAdd(gridCellProps.dataItem)}
                                    >
                                        <BsBasket3Fill size={20} />
                                    </button>
                                )}
                            </CustomGridCell>
                        )}
                    />

                    <GridColumn
                        field="AttachmentManifestUrl"
                        title="Info"
                        width={90}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AttachmentManifestUrl && <ExternalLinkView href={props.dataItem.AttachmentManifestUrl}>Listing</ExternalLinkView>}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="AttachmentArchiveFileName"
                        title="Download"
                        width={160}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AttachmentArchiveUrl && <DownloadLinkView href={props.dataItem.AttachmentArchiveUrl}>{props.dataItem.AttachmentArchiveFileName}</DownloadLinkView>}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="AttachmentArchiveFileSize"
                        title="File Size"
                        width={140}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AttachmentArchiveFileSize && GlobalHelpers.convertBytesToString(props.dataItem.AttachmentArchiveFileSize)}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="ReportTitle" title="Data Description" width={600} filter="text" />
                    <GridColumn field="ReportType" title="Report Type" width={200} />
                    <GridColumn field="Company" title="Company" width={250} />
                    <GridColumn field="TapeId" title="Tape ID" width={100} />
                    <GridColumn field="ReportItemId" title="Item ID" width={100} />
                    <GridColumn field="SurveyIdentifier" title="Survey ID" width={150} />
                    <GridColumn field="SurveyName" title="Survey" width={250} />
                    <GridColumn field="AcquisitionIdentifier" title="Acquisition ID" width={150} />
                    <GridColumn field="AcquisitionName" title="Acquisition" width={250} />
                </Grid>
            </div>
        </>
    );
}
