import { storageFormationsListActions, IStorageFormationsListActions } from "./storageFormationsListActions";
import * as Client from "../../../../api/Client";

// initial state
export interface IStorageFormationsListState {
    storageFormations?: Client.GetPublicStorageFormationListItemDto[] | undefined;
}

const initialState: IStorageFormationsListState = {
    storageFormations: [],
};

// reducers
export function storageFormationsListReducer(state: IStorageFormationsListState = initialState, action: IStorageFormationsListActions): IStorageFormationsListState {
    switch (action.type) {
        case storageFormationsListActions.ListStorageFormationsRequest:
            return {
                ...state,
                storageFormations: [],
            };

        case storageFormationsListActions.ListStorageFormationsResponse:
            return {
                ...state,
                storageFormations: action.storageFormations,
            };

        default:
            return state;
    }
}
