import NopimsPageLayout from "../shell/NopimsPageLayout";
import { nopimsLhsNavItemEnum } from "../shell/NopimsLhsNav";
import React from "react";
import ExternalLinkView from "../../common/externalLink/ExternalLinkView";
import EmailLinkView from "../../common/externalLink/EmailLinkView";
import CopyODataLinkView from "../../common/odata/CopyODataLinkView";

export default function Controller(): React.JSX.Element {
    return (
        <NopimsPageLayout title={[""]} lhsNavItem={nopimsLhsNavItemEnum.Home}>
            <div className="container p-0">
                <h1 className="fw-bold">Welcome to the National Offshore Petroleum Information Management System</h1>
                <p>
                    The National Offshore Petroleum Information Management System (NOPIMS) provides access to disclosable, public information concerning offshore petroleum and greenhouse gas storage
                    activities, submitted under the <i>Offshore Petroleum and Greenhouse Gas Storage Act 2006</i>, in accordance with the Offshore Petroleum and Greenhouse Gas Storage (Resource
                    Management and Administration) Regulations 2011 (the RMA Regulations) and predecessors.
                </p>
                <p>
                    NOPIMS has been re-developed by NOPTA and is now integrated with NEATS, the integration of these two systems will continue over time. Partnerships with Geoscience Australia (GA)
                    and the Western Australian Department of Energy, Mines, Industry Regulation and Safety (DEMIRS) remain in place with the agencies working collaboratively under the National
                    Offshore Petroleum Data Core Repository agreement, providing services for physical sample access and data.
                </p>
                <p>The key features of NOPIMS include:</p>
                <ul>
                    <li>The ability to search for well data, survey and reprocessed survey data using a range of search criteria.</li>
                    <li>Discovery of activities conducted under the RMA Regulations upon commencement of an activity and prior to the release of that information.</li>
                    <li>Download data for activities that are releasable under the RMA Regulations following the expiration of specified confidentiality periods.</li>
                    <li>An interactive map representing the activities within NOPIMS.</li>
                </ul>

                <h4>Metadata Export - Access Data Feed (OData)</h4>
                <ul>
                    <li>To view and filter the NOPIMS metadata in Excel format, this function is available via Open Data Protocol (OData).</li>
                    <li>
                        To open this data in Microsoft Excel, select Data {"\u2192"} Get Data {"\u2192"} From Other Sources {"\u2192"} From OData Feed, and enter the applicable URL from the button
                        selection below.
                    </li>
                </ul>
                <CopyODataLinkView title="Wells" oDataPath="/odata/v1/public/nopims/well/PublicNopimsWells" />
                <CopyODataLinkView title="Surveys" oDataPath="/odata/v1/public/nopims/survey/PublicNopimsSurvey" />
                <CopyODataLinkView title="Reprocessings" oDataPath="/odata/v1/public/nopims/reprocessing/PublicNopimsReprocessings" />
                <CopyODataLinkView title="Released Documents" oDataPath="/odata/v1/public/nopims/releasedDocument/PublicNopimsReleasedDocument" />
                <CopyODataLinkView title="Core Library" oDataPath="/odata/v1/public/nopims/coreLibrary/PublicNopimsCoreLibrary" />
                <br />
                <br />
                <p>
                    <strong>
                        Please use the <ExternalLinkView href="/nopims/forms/feedback">Feedback Request</ExternalLinkView> for data specific and general system feedback, such as data availability,
                        missing data, data quality, and user queries.
                    </strong>
                </p>

                <p>
                    For more information on the public release of data, please visit{" "}
                    <ExternalLinkView href="https://www.nopta.gov.au/maps-and-public-data/public-release.html" className="card-link">
                        www.nopta.gov.au/maps-and-public-data/public-release.html
                    </ExternalLinkView>
                    . For general data submission enquiries, please contact <EmailLinkView emailAddress="data@nopta.gov.au" />.
                </p>
                <p>
                    For NOPIMS system enquiries, accessibility and technical support, please email <EmailLinkView emailAddress="nopims.admin@nopta.gov.au" />. NOPIMS will continue to be enhanced over
                    time. If you would like to be involved in workshops on the future development of NOPIMS, please register via <EmailLinkView emailAddress="nopims.admin@nopta.gov.au" />.
                </p>
                <p>
                    To request publicly released well and survey data not available via NOPIMS, such as survey field data, or a personalised data package, please use the add to “Basket” function, or
                    contact Geoscience Australia at <EmailLinkView emailAddress="ausgeodata@ga.gov.au" />.
                </p>
                <p>
                    To learn more about the services, data and projects provided by GA, please visit{" "}
                    <ExternalLinkView href="https://www.ga.gov.au/" className="card-link">
                        www.ga.gov.au
                    </ExternalLinkView>
                    .
                </p>
                <p>
                    To learn more about NOPTA and to access links to legislation, regulations and other guidance material, please visit{" "}
                    <ExternalLinkView href="https://www.nopta.gov.au" className="card-link">
                        www.nopta.gov.au
                    </ExternalLinkView>
                    .
                </p>
            </div>
        </NopimsPageLayout>
    );
}
