import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userTokenLoaded } from "../../shell/redux/shellActions";

export default function UserTokenController(): React.JSX.Element {
    const key = "UserToken";
    const dispatch = useDispatch();

    useEffect(() => {
        let value = localStorage.getItem(key);
        if (!value) {
            value = crypto.randomUUID();
            localStorage.setItem(key, value);
        }
        dispatch(userTokenLoaded(value));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <></>;
}
