import { INopimsBasketItemModel } from "./model";

export enum NopimsBasketActions {
    SetUser = "NOPIMS_BASKET_SET_USER",
    Initialise = "NOPIMS_BASKET_INITIALISE",
    ItemsLoaded = "NOPIMS_BASKET_ITEMS_LOADED",
    ItemsAdded = "NOPIMS_BASKET_ITEMS_ADDED",
    ItemsRemoved = "NOPIMS_BASKET_ITEMS_REMOVED",
}

export interface INopimsBasketActions {
    type: NopimsBasketActions;
    itemId?: string | undefined;
    items?: Array<INopimsBasketItemModel> | undefined;
    userToken?: string | undefined;
}

export function setUser(userToken?: string | undefined): INopimsBasketActions {
    return {
        type: NopimsBasketActions.SetUser,
        userToken: userToken,
    };
}

export function initialise(): INopimsBasketActions {
    return {
        type: NopimsBasketActions.Initialise,
    };
}

export function itemsLoaded(items: Array<INopimsBasketItemModel>): INopimsBasketActions {
    return {
        type: NopimsBasketActions.ItemsLoaded,
        items: items,
    };
}

export function itemsAdded(items: Array<INopimsBasketItemModel>): INopimsBasketActions {
    return {
        type: NopimsBasketActions.ItemsAdded,
        items: items,
    };
}

export function itemsRemoved(items: Array<INopimsBasketItemModel>): INopimsBasketActions {
    return {
        type: NopimsBasketActions.ItemsRemoved,
        items: items,
    };
}
