// framework
import { takeEvery, call, put } from "redux-saga/effects";
// redux
import { IOeiApplicationTrackingDetailsActions, oeiApplicationTrackingDetailsActions, getOeiApplicationTrackingDetailsResponseAction } from "./oeiApplicationTrackingDetailsActions";
// api
import { createOeiApplicationsClient } from "../../../../api/clientFactory";
import { simpleCall } from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../../../shell/redux/shellActions";

export default function* watcherSaga() {
    yield takeEvery(oeiApplicationTrackingDetailsActions.GetOeiApplicationTrackingDetailsRequest, workerSaga);
}

function* workerSaga(action: IOeiApplicationTrackingDetailsActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicOeiApplicationsTrackingDetailsResponseDto = yield call(query, action.applicationId!);
        // post response
        yield put(getOeiApplicationTrackingDetailsResponseAction(response));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function query(applicationId: string): Promise<Client.GetPublicOeiApplicationsTrackingDetailsResponseDto> {
    const client = createOeiApplicationsClient();

    const request = new Client.GetPublicOeiApplicationsTrackingDetailsRequestDto({ applicationId: applicationId });

    const response = await simpleCall(() => client.getPublicOeiApplicationsTrackingDetails(request));
    return response;
}
