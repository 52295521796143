import { connect } from "react-redux";

function BusyOverlayController(props: { busyCount: number }) {
    const isBusy = props.busyCount > 0;

    return <LoadingPanel isBusy={isBusy} />;
}

function LoadingPanel(props: { isBusy: boolean }) {
    const isBusy = props.isBusy;

    if (!isBusy) return <></>;

    return (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
}

// connect redux
export default connect((state: any) => ({ busyCount: state.shell.busyCount }))(BusyOverlayController);
