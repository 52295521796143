// framework
import React from "react";
// kendo
import { FieldSettings, Filter, FilterChangeEvent } from "@progress/kendo-react-data-tools";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { NumericTextBoxPropsContext } from "@progress/kendo-react-inputs";
import { DateInputPropsContext } from "@progress/kendo-react-dateinputs";

// NOTE: Using PropsContext.Provider to set the placeholder. Feature request for allowing a placeholder set for all Filters is below.
// https://feedback.telerik.com/kendo-react-ui/1664642-external-filter-props-placeholder
// NOTE2: We are required to use TextBoxFilter to create a Placeholder for any Text inputs, using a InputPropsContext with the GridFilter be a better solution but there is a bug preventing that, see details below.
// https://github.com/telerik/kendo-react/issues/2525

interface IProps {
    fields: Array<FieldSettings>;
    value: CompositeFilterDescriptor;
    placeholder?: string | undefined;
    onChange: (event: FilterChangeEvent) => void;
}

const css = `
        .k-filter .k-filter-toolbar .k-toolbar-item .k-dropdownlist {
            width: 230px;
        }
        .k-filter .k-filter-toolbar .k-toolbar-item.k-filter-value {
            .k-dropdownlist {
                width: 170px;
            }            
            .k-numerictextbox {
                width: 170px;
            }
            .k-datepicker {
                width: 170px;
            }
            .k-textbox {
                width: 170px;
                placeholder: 'test';
            }
        }        
    `;

export default function GridFilter(props: IProps) {
    const propsDateCallback = React.useCallback((props: any) => {
        return {
            ...props,
            placeholder: props.placeholder ?? "day/month/year",
        };
    }, []);
    const propsNumericCallback = React.useCallback((props: any) => {
        return {
            ...props,
            placeholder: props.placeholder ?? "filter",
        };
    }, []);

    return (
        <DateInputPropsContext.Provider value={propsDateCallback}>
            <NumericTextBoxPropsContext.Provider value={propsNumericCallback}>
                <style>{css}</style>
                <Filter fields={props.fields} value={props.value} onChange={props.onChange} ariaLabelGroup="Advanced Filter Options" ariaLabelExpression="Filter Expression" />
            </NumericTextBoxPropsContext.Provider>
        </DateInputPropsContext.Provider>
    );
}
