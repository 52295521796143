export default class SelectedItemsHelper<T> {
    constructor(items: Array<T>, setItems: (items: Array<T>) => void, comparer: (item1: T, item2: T) => boolean) {
        this._items = items;
        this._comparer = comparer;
        this._setItems = setItems;
    }

    private _items: Array<T>;
    private _setItems: (items: Array<T>) => void;
    private _comparer: (item1: T, item2: T) => boolean;

    TryAdd(item: T): void {
        if (this._items.find((i) => this._comparer(i, item))) return;

        const newItems = this._items.concat([item]);
        this._setItems(newItems);
    }

    TryAddAll(items: T[] | undefined): void {
        if (!items) return;

        let newItems = [...this._items];
        for (var i = 0; i < items.length; i++) {
            const item = items[i];

            if (this._items.find((i) => this._comparer(i, item))) continue;
            newItems = newItems.concat([item]);
        }
        if (this._items !== newItems) this._setItems(newItems);
    }

    TryRemove(item: T): void {
        let newItems = [...this._items];
        newItems = newItems.filter((i) => !this._comparer(i, item));
        if (this._items !== newItems) this._setItems(newItems);
    }

    Clear(): void {
        if (this._items.length === 0) return;
        this._setItems([]);
    }
}
