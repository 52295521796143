import { clone } from "lodash";

export interface INopimsBasketModel {
    userToken: string | undefined;
    items: Array<INopimsBasketItemModel>;

    setUserToken(userToken: string): INopimsBasketModel;
    refreshItemsLoaded(items: Array<INopimsBasketItemModel>): INopimsBasketModel;
    refreshItemsAdded(items: Array<INopimsBasketItemModel>): INopimsBasketModel;
    refreshItemsRemoved(items: Array<INopimsBasketItemModel>): INopimsBasketModel;
}

export interface INopimsBasketItemModel {
    reportId: number | undefined;
    surveyDataId: number | undefined;
    dataDescription: string;
    wellBoreholeId: number | undefined;
    surveyAcquisitionId: number | undefined;
    reprocessingProjectId: number | undefined;

    itemId: string | undefined;
    activity: string | undefined;
    activityIdentifier: string | undefined;
    activityName: string | undefined;
    type: string | undefined;
    documentType: string | undefined;
}

export class NopimsBasketModel implements INopimsBasketModel {
    constructor() {
        this.items = [];
    }

    userToken: string | undefined;
    items: Array<INopimsBasketItemModel>;

    setUserToken(userToken: string): INopimsBasketModel {
        const m = clone(this);
        m.userToken = userToken;
        return m;
    }

    refreshItemsLoaded(items: Array<INopimsBasketItemModel>): INopimsBasketModel {
        const m = clone(this);
        m.items = items;
        return m;
    }

    refreshItemsAdded(items: Array<INopimsBasketItemModel>): INopimsBasketModel {
        // ensure added items don't match existing items on keys (report id, survey id)
        const m = clone(this);

        // Get the distinct items passed in
        var distinctItems = items.reduce(function (itemList: Array<INopimsBasketItemModel>, i: INopimsBasketItemModel) {
            var matches = itemList.filter(function (i2) {
                return (i2.reportId && i2.reportId === i.reportId) || (i2.surveyDataId && i2.surveyDataId === i.surveyDataId);
            });
            if (matches.length === 0) itemList.push(i);
            return itemList;
        }, []);

        m.items = m.items.concat(distinctItems.filter((i) => !m.items.some((i2) => (i2.reportId && i2.reportId === i.reportId) || (i2.surveyDataId && i2.surveyDataId === i.surveyDataId))));
        return m;
    }

    refreshItemsRemoved(items: Array<INopimsBasketItemModel>): INopimsBasketModel {
        // items can only be removed if they were actually in the list
        const m = clone(this);
        m.items = m.items.filter((i) => !items.some((i2) => (i2.reportId && i2.reportId === i.reportId) || (i2.surveyDataId && i2.surveyDataId === i.surveyDataId)));
        return m;
    }
}

export class NopimsBasketItemModel implements INopimsBasketItemModel {
    constructor(
        dataDescription: string,
        reportId: number | undefined,
        surveyDataId: number | undefined,
        wellBoreholeId: number | undefined,
        surveyAcquisitionId: number | undefined,
        reprocessingProjectId: number | undefined,
        itemId?: string | undefined,
        activity?: string | undefined,
        activityIdentifier?: string | undefined,
        activityName?: string | undefined,
        type?: string | undefined,
        documentType?: string | undefined
    ) {
        this.dataDescription = dataDescription && dataDescription !== `` ? dataDescription : itemId ?? ``;
        this.reportId = reportId;
        this.surveyDataId = surveyDataId;
        this.wellBoreholeId = wellBoreholeId;
        this.surveyAcquisitionId = surveyAcquisitionId;
        this.reprocessingProjectId = reprocessingProjectId;
        this.itemId = itemId;
        this.activity = activity;
        this.activityIdentifier = activityIdentifier;
        this.activityName = activityName;
        this.type = type;
        this.documentType = documentType;
    }

    dataDescription: string;
    reportId: number | undefined;
    surveyDataId: number | undefined;
    wellBoreholeId: number | undefined;
    surveyAcquisitionId: number | undefined;
    reprocessingProjectId: number | undefined;
    itemId: string | undefined;
    activity: string | undefined;
    activityName: string | undefined;
    activityIdentifier: string | undefined;
    type: string | undefined;
    documentType: string | undefined;
}
