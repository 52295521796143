import { createStore, applyMiddleware } from "redux";
import rootReducer from "./rootReducer";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import createSagaMiddleware from "redux-saga";
// user sagas
import searchTitleSaga from "../opggs/title/titleSearch/redux/titleSearchSaga";
import getTitleDetailsSaga from "../opggs/title/titleDetails/redux/titleDetailsSaga";
import getTitleMapDetailsSaga from "../opggs/title/titleDetails/redux/titleMapDetailsSaga";
import getOpggsApplicationTrackingSaga from "../opggs/application/opggsApplicationTracking/redux/opggsApplicationTrackingSaga";
import getOpggsApplicationTrackingDetailsSaga from "../opggs/application/opggsApplicationTrackingDetails/redux/opggsApplicationTrackingDetailsSaga";
import storageFormationsListSaga from "../opggs/storageFormation/storageFormationsList/redux/storageFormationsListSaga";
import openTitleSaga from "../open/title/redux/openTitleSaga";
import shellSaga from "../shell/redux/shellSaga";
import searchOeiLicenceSaga from "../oei/licence/search/redux/licenceSearchSaga";
import getOeiLicenceDetailsSaga from "../oei/licence/details/redux/licenceDetailsSaga";
import openLicenceSaga from "../open/licence/redux/openLicenceSaga";
import getOeiApplicationTrackingSaga from "../oei/application/oeiApplicationTracking/redux/oeiApplicationTrackingSaga";
import getOeiApplicationTrackingDetailsSaga from "../oei/application/oeiApplicationTrackingDetails/redux/oeiApplicationTrackingDetailsSaga";
import nopimsBasketSaga from "../nopims/basket/redux/saga";

const initialiseSagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    // this will warn us if we accidentally mutate state
    composeWithDevToolsDevelopmentOnly(applyMiddleware(reduxImmutableStateInvariant(), initialiseSagaMiddleware))
);

// register Sagas
// TODO, can this be externalised somehow?

// Title
initialiseSagaMiddleware.run(searchTitleSaga);
initialiseSagaMiddleware.run(getTitleDetailsSaga);
initialiseSagaMiddleware.run(getTitleMapDetailsSaga);
// Storage Formations
initialiseSagaMiddleware.run(storageFormationsListSaga);
// Application
initialiseSagaMiddleware.run(getOpggsApplicationTrackingSaga);
initialiseSagaMiddleware.run(getOpggsApplicationTrackingDetailsSaga);
// Open
initialiseSagaMiddleware.run(openTitleSaga);
initialiseSagaMiddleware.run(openLicenceSaga);
// Oei
initialiseSagaMiddleware.run(searchOeiLicenceSaga);
initialiseSagaMiddleware.run(getOeiLicenceDetailsSaga);
// Oei Application
initialiseSagaMiddleware.run(getOeiApplicationTrackingSaga);
initialiseSagaMiddleware.run(getOeiApplicationTrackingDetailsSaga);
// NOPIMS
initialiseSagaMiddleware.run(nopimsBasketSaga);
// Shell
initialiseSagaMiddleware.run(shellSaga);

//show initialised state
// console.log(store.getState());

export default store;
