import NopimsPageLayout from "../shell/NopimsPageLayout";
import { nopimsLhsNavItemEnum } from "../shell/NopimsLhsNav";
import ExternalLinkView from "../../common/externalLink/ExternalLinkView";
import { Alert } from "react-bootstrap";
import { InfoIcon } from "@primer/octicons-react";

export default function Controller(): React.JSX.Element {
    const url = process.env.REACT_APP_MAP_NOPIMS;

    // the GIS Map uses a dark theme, as such provide a dark border to soften its presence in the page

    return (
        <NopimsPageLayout title={["GIS Map"]} lhsNavItem={nopimsLhsNavItemEnum.GisMap}>
            <Alert variant="primary" className="mb-3">
                <InfoIcon className="d-inline me-2" />
                For the best experience open the <ExternalLinkView href={url}>GIS Map</ExternalLinkView> in a new browser window.
            </Alert>

            <div className="card" style={{ height: "75vh", backgroundColor: "black" }}>
                <div className="card-body">
                    <iframe
                        title="An interactive map presenting NOPIMS Wells, Surveys and Reprocessing that exist in Australian territorial waters. The map is presented in an iframe with data sourced from NOPTA's ArcGIS online mapping service."
                        src={url}
                        style={{ height: "100%", width: "100%", border: "none" }}
                    ></iframe>
                </div>
            </div>
        </NopimsPageLayout>
    );
}
