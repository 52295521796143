// framework
import { takeEvery, call, put } from "redux-saga/effects";
// redux
import { IStorageFormationsListActions, storageFormationsListActions, storageFormationsListResponseAction } from "./storageFormationsListActions";
// api
import { createOpggsStorageFormationsClient } from "../../../../api/clientFactory";
import { simpleCall } from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../../../shell/redux/shellActions";

export default function* watcherSaga() {
    yield takeEvery(storageFormationsListActions.ListStorageFormationsRequest, workerSaga);
}

function* workerSaga(action: IStorageFormationsListActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicStorageFormationListItemDto[] = yield call(query);
        // post response
        yield put(storageFormationsListResponseAction(response));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function query(): Promise<Client.GetPublicStorageFormationListItemDto[]> {
    const client = createOpggsStorageFormationsClient();
    const response = await simpleCall(() => client.getPublicStorageFormations(new Client.GetPublicStorageFormationsListRequestDto()));
    return response.storageFormations;
}
