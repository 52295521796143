import * as Client from "../../../../api/Client";

export enum storageFormationsListActions {
    ListStorageFormationsRequest = "OPGGS_STORAGE_FORMATION_LIST.LIST_STORAGE_FORMATIONS_REQUEST",
    ListStorageFormationsResponse = "OPGGS_STORAGE_FORMATION_LIST.LIST_STORAGE_FORMATIONS_RESPONSE",
}

export interface IStorageFormationsListActions {
    type: storageFormationsListActions;
    storageFormations?: Client.GetPublicStorageFormationListItemDto[] | undefined;
}

export function storageFormationsListRequestAction(): IStorageFormationsListActions {
    return {
        type: storageFormationsListActions.ListStorageFormationsRequest,
    };
}

export function storageFormationsListResponseAction(storageFormations: Client.GetPublicStorageFormationListItemDto[]): IStorageFormationsListActions {
    return {
        type: storageFormationsListActions.ListStorageFormationsResponse,
        storageFormations: storageFormations,
    };
}
