// framework
import { useEffect, useRef } from "react";
import { Alert } from "react-bootstrap";
import { IssueReopenedIcon } from "@primer/octicons-react";

export default function ConflictAlertView(props: { isConflict: boolean; children?: React.ReactNode }) {
    const alertRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // If the alert appears under the floating top nav then it focuses but the screen doesn't know it needs to scroll to the top and the alert stays hidden.
        // The scroll to top below has been added as a temporary workaround until we can look at possibly integrating conflict and status alert views with the body layout.
        if (props.isConflict) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            alertRef.current?.focus();
        }
    }, [props.isConflict]);

    if (!props.isConflict) return <></>;

    const children = props.children ?? (
        <span>Unable to complete the operation as this data has been modified by another user or process. You will need to cancel your changes, refresh the screen and try again.</span>
    );

    return (
        <div ref={alertRef} className="mb-2" tabIndex={-1}>
            <Alert variant="danger" className="mb-1">
                <IssueReopenedIcon className="d-inline me-1" />
                {children}
            </Alert>
        </div>
    );
}
