import { useSelector } from "react-redux";
import { INopimsBasketActions, NopimsBasketActions } from "./actions";
import { INopimsBasketModel, NopimsBasketModel } from "./model";

export function reducer(state: INopimsBasketModel = new NopimsBasketModel(), action: INopimsBasketActions): INopimsBasketModel {
    switch (action.type) {
        case NopimsBasketActions.Initialise:
            // handled by saga
            return state;

        case NopimsBasketActions.SetUser:
            return state.setUserToken(action.userToken!);

        case NopimsBasketActions.ItemsLoaded:
            return state.refreshItemsLoaded(action.items!);

        case NopimsBasketActions.ItemsAdded:
            return state.refreshItemsAdded(action.items!);

        case NopimsBasketActions.ItemsRemoved:
            return state.refreshItemsRemoved(action.items!);

        default:
            return state;
    }
}

export function useNopimsBasket(): INopimsBasketModel {
    const state = useSelector((state: any) => state.nopimsBasket);
    return state;
}
