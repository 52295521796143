// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { setLayout } from "../../../../shell/redux/shellActions";
// api
import * as Client from "../../../../api/Client";
// other
import StorageFormationListView from "./StorageFormationsListView";
import { storageFormationsListRequestAction } from "../redux/storageFormationsListActions";
import { IStorageFormationsListState } from "../redux/storageFormationsListReducers";

function StorageFormationsListController(props: any) {
    const { setLayout, storageFormationsListRequestAction } = props;

    const state: IStorageFormationsListState = props.state;
    const storageFormations = state.storageFormations ?? new Array<Client.GetPublicStorageFormationListItemDto>();

    // initial load
    useEffect(() => {
        setLayout("The Register of Identified Greenhouse Gas Storage Formations");
        storageFormationsListRequestAction();
    }, [setLayout, storageFormationsListRequestAction]);

    // return
    return <StorageFormationListView storageFormations={storageFormations} />;
}

// connect redux
export default connect((state: any) => ({ state: state.storageFormationsList }), { setLayout, storageFormationsListRequestAction })(StorageFormationsListController);
