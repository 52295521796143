// framework
import { useState, useEffect } from "react";
// api
import { GetSubmitPublicNopimsBasketReferenceDataResponseCountrySubDivisionDto } from "../../../api/Client";
// common
import * as Forms from "../../../common/forms/BootstrapForms";
import { IValidationModel } from "../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../common/validation/ValidationMessageView";
import KendoComboBox from "../../../common/kendo/KendoComboBox";
import KendoDropDownList from "../../../common/kendo/KendoDropDownList";
// models
import { IRootViewModel, ISubmitBasketViewModel } from "./models/models";

interface IProps {
    vm: IRootViewModel;
    setVm: (vm: IRootViewModel) => void;
    validation: IValidationModel;
    onCancel: () => void;
    onSubmit: () => void;
}

export default function SubmitViewDetails(props: IProps): React.JSX.Element {
    const { vm, setVm, validation, onCancel, onSubmit } = props;

    const [state, setState] = useState<ISubmitBasketViewModel>(vm.submitDetails);
    const honorifics = vm.referenceData.honorifics;
    const countries = vm.referenceData.countries;
    const subdivisions = vm.referenceData.countrySubDivisions;

    useEffect(() => {
        setState(vm.submitDetails);
    }, [vm.submitDetails]);

    const [filteredCountrySubDivisions, setFilteredCountrySubDivisions] = useState<GetSubmitPublicNopimsBasketReferenceDataResponseCountrySubDivisionDto[]>([]);
    useEffect(() => {
        setFilteredCountrySubDivisions(state.customerCountry ? subdivisions.filter((s) => s.countryId === state.customerCountry!.id) : []);
    }, [subdivisions, state.customerCountry]);

    function updateViewModel() {
        setVm(vm.refreshSubmitDetails({ ...state }));
    }

    function updateStateAndViewModel(state: ISubmitBasketViewModel) {
        setState(state);
        setVm(vm.refreshSubmitDetails({ ...state }));
    }

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Contact Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.Label htmlFor="honorificField">Title</Forms.Label>
                                <KendoComboBox
                                    id="honorificField"
                                    data={honorifics}
                                    keyField="id"
                                    textField="name"
                                    value={state.customerHonorific}
                                    onChange={(value) => updateStateAndViewModel({ ...state, customerHonorific: value })}
                                    onBlur={updateViewModel}
                                />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerFirstNameField"
                                    label="First Name"
                                    value={state.customerFirstName}
                                    valid={validation.applyValidity("CreateSubmitDetails.CustomerFirstName")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerFirstName: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateSubmitDetails.CustomerFirstName" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerLastNameField"
                                    label="Last Name"
                                    value={state.customerLastName}
                                    valid={validation.applyValidity("CreateSubmitDetails.CustomerLastName")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerLastName: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateSubmitDetails.CustomerLastName" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerCompanyNameField"
                                    label="Company"
                                    value={state.customerCompanyName}
                                    maxLength={1000}
                                    onChange={(value) => setState({ ...state, customerCompanyName: value })}
                                    onBlur={updateViewModel}
                                />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerContactNumberField"
                                    label="Contact Number"
                                    value={state.customerContactNumber}
                                    valid={validation.applyValidity("CreateSubmitDetails.CustomerContactNumber")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerContactNumber: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateSubmitDetails.CustomerContactNumber" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerEmailAddressField"
                                    label="Email"
                                    value={state.customerEmailAddress}
                                    valid={validation.applyValidity("CreateSubmitDetails.CustomerEmailAddress")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerEmailAddress: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateSubmitDetails.CustomerEmailAddress" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerAddressField"
                                    label="Street Address"
                                    value={state.customerAddress}
                                    valid={validation.applyValidity("CreateSubmitDetails.CustomerAddress")}
                                    maxLength={1000}
                                    onChange={(value) => setState({ ...state, customerAddress: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateSubmitDetails.CustomerAddress" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerSuburbField"
                                    label="Town/Suburb"
                                    value={state.customerSuburb}
                                    valid={validation.applyValidity("CreateSubmitDetails.CustomerSuburb")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerSuburb: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateSubmitDetails.CustomerSuburb" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="customerPostcodeField"
                                    label="Postcode"
                                    value={state.customerPostcode}
                                    valid={validation.applyValidity("CreateSubmitDetails.CustomerPostcode")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, customerPostcode: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateSubmitDetails.CustomerPostcode" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.Label htmlFor="countrySubDivisionField">State/Territory</Forms.Label>
                                <KendoDropDownList
                                    id="countrySubDivisionField"
                                    data={filteredCountrySubDivisions}
                                    keyField="id"
                                    textField="name"
                                    value={state.customerCountrySubdivision}
                                    disabled={!state.customerCountry}
                                    valid={validation.applyValidity("CreateSubmitDetails.CountrySubDivision")}
                                    onChange={(value) => updateStateAndViewModel({ ...state, customerCountrySubdivision: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateSubmitDetails.CountrySubDivision" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.Label htmlFor="countryField">Country</Forms.Label>
                                <KendoDropDownList
                                    id="countryField"
                                    data={countries}
                                    keyField="id"
                                    textField="name"
                                    value={state.customerCountry}
                                    onChange={(value) => updateStateAndViewModel({ ...state, customerCountry: value, customerCountrySubdivision: undefined })}
                                    onBlur={updateViewModel}
                                    valid={validation.applyValidity("CreateSubmitDetails.CustomerCountry")}
                                />
                                <ValidationMessageView name="CreateSubmitDetails.CustomerCountry" validationModel={validation} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.FullColumn>
                                <Forms.TextArea
                                    id="customerCommentsField"
                                    label="Comments"
                                    value={state.customerComments}
                                    valid={validation.applyValidity("CreateSubmitDetails.CustomerComments")}
                                    maxLength={1000}
                                    onChange={(value) => setState({ ...state, customerComments: value })}
                                    onBlur={updateViewModel}
                                    rows={5}
                                />
                                <ValidationMessageView name="CreateSubmitDetails.CustomerComments" validationModel={validation} />
                            </Forms.FullColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <div className="mt-3">
                        <button className="btn btn-primary me-2" type="button" onClick={onSubmit}>
                            Submit
                        </button>
                        <button className="btn btn-primary me-2" type="button" onClick={onCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
