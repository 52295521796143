import { IRouteItem, RouteBuilder } from "../infrastructure/routeItem";
import { FeatureSwitch } from "../common/shell/shellHelper";
import { NeatsFeatureSwitchEnum } from "../api/Client";
import * as RouteHelpers from "./routeHelper";

import HomeController from "./home/Controller";
import WellsController from "./wells/Controller";
import SurveysController from "./surveys/Controller";
import ReprocessingController from "./reprocessing/Controller";
import ReleasedDocumentsController from "./releasedDocuments/Controller";
import CoreLibraryController from "./coreLibrary/coreLibraryRequest/Controller";
import GisMapController from "./gisMap/Controller";
import FeedbackController from "./feedback/Controller";
import CoreCuttingsRequestController from "./coreLibrary/coreCuttingsRequest/Controller";
import BasketController from "./basket/details/Controller";

const routeBuilder: RouteBuilder = (featureSwitch: FeatureSwitch) => {
    let routes = new Array<IRouteItem>();

    if (featureSwitch.check(NeatsFeatureSwitchEnum.Nopims)) {
        routes.push({ path: RouteHelpers.home(), exact: true, element: <HomeController /> });
        routes.push({ path: RouteHelpers.wells(), exact: true, element: <WellsController /> });
        routes.push({ path: RouteHelpers.surveys(), exact: true, element: <SurveysController /> });
        routes.push({ path: RouteHelpers.reprocessing(), exact: true, element: <ReprocessingController /> });
        routes.push({ path: RouteHelpers.releasedDocuments(), exact: true, element: <ReleasedDocumentsController /> });
        routes.push({ path: RouteHelpers.coreLibrary(), exact: true, element: <CoreLibraryController /> });
        routes.push({ path: RouteHelpers.gisMap(), exact: true, element: <GisMapController /> });
        routes.push({ path: RouteHelpers.formsFeedback(), exact: true, element: <FeedbackController /> });
        routes.push({ path: RouteHelpers.formsCoreCuttingsRequest(), exact: true, element: <CoreCuttingsRequestController /> });
        routes.push({ path: RouteHelpers.basket(), exact: true, element: <BasketController /> });
    }
    return routes;
};

export default routeBuilder;
