import { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsBasket3Fill } from "react-icons/bs";
import * as RouteHelper from "../../routeHelper";
import SelectedItems from "../../../common/selectedItems/SelectedItems";
import { useDispatch } from "react-redux";
import { INopimsBasketItemModel } from "../redux/model";
import * as Actions from "../redux/actions";
import { useNopimsBasket } from "../redux/reducers";
import { useUserToken } from "../../../common/shell/shellHelper";

export default function Controller(): React.JSX.Element {
    const dispatch = useDispatch();
    const nopimsBasketModel = useNopimsBasket();
    const userToken = useUserToken();

    const onFocus = () => {
        // reload the basket from the service
        dispatch(Actions.initialise());
    };

    useEffect(() => {
        // on mount load the basket from the service
        dispatch(Actions.setUser(userToken));
        dispatch(Actions.initialise());

        // register a event handler to reinitialise the basket on window focus
        window.addEventListener("focus", onFocus);
        // and remove the event handler on unload
        return () => {
            window.removeEventListener("focus", onFocus);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function onItemRemoved(item: INopimsBasketItemModel): void {
        dispatch(Actions.itemsRemoved([item]));
    }

    function onItemsCleared(): void {
        dispatch(Actions.itemsRemoved(nopimsBasketModel.items));
    }

    return (
        <>
            <div className="card mb-2 overflow-hidden" style={{ maxHeight: 500 }}>
                <div className="card-header px-2 py-2">
                    <Link to={RouteHelper.basket}>Manage Basket</Link>
                    <div className="float-end">
                        <span className="me-1">
                            <BsBasket3Fill size={24} />
                        </span>
                        <span>{nopimsBasketModel.items.length}</span>
                    </div>
                </div>
                <div className="card-body p-2 scrollable overflow-auto">
                    <SelectedItems
                        title="Document"
                        showContainer={false}
                        items={nopimsBasketModel.items}
                        getItemTitle={(i: INopimsBasketItemModel) => i.dataDescription}
                        getItemTooltip={(i: INopimsBasketItemModel) => i.activity + "'s Document from the Basket"}
                        onItemRemoved={onItemRemoved}
                        onItemsCleared={onItemsCleared}
                    ></SelectedItems>
                </div>
            </div>
        </>
    );
}
