// framework
import * as Validation from "../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    if (!vm.submitDetails.customerFirstName) {
        v.addError("CreateSubmitDetails.CustomerFirstName", "First Name is required.");
    }

    if (!vm.submitDetails.customerLastName) {
        v.addError("CreateSubmitDetails.CustomerLastName", "Last Name is required.");
    }

    if (!vm.submitDetails.customerContactNumber) {
        v.addError("CreateSubmitDetails.CustomerContactNumber", "Contact Number is required.");
    }

    if (!vm.submitDetails.customerEmailAddress) {
        v.addError("CreateSubmitDetails.CustomerEmailAddress", "Email is required.");
    } else if (!Validation.isValidEmail(vm.submitDetails.customerEmailAddress!)) {
        v.addError("CreateSubmitDetails.CustomerEmailAddress", "Email is not valid.");
    }

    if (!vm.submitDetails.customerAddress) {
        v.addError("CreateSubmitDetails.CustomerAddress", "Street Address is required.");
    }

    if (!vm.submitDetails.customerSuburb) {
        v.addError("CreateSubmitDetails.CustomerSuburb", "Town/Suburb is required.");
    }

    if (!vm.submitDetails.customerCountry) {
        v.addError("CreateSubmitDetails.CustomerCountry", "Country is required.");
    }

    if (!vm.submitDetails.customerComments) {
        v.addError("CreateSubmitDetails.CustomerComments", "Comments are required.");
    }

    return v;
}
