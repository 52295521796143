// framework
import { useState, useEffect } from "react";
import TaskLayout from "../../../common/shell/TaskLayout";
// redux
import * as SubmitActions from "./submitActions";
import { useNopimsBasket } from "../redux/reducers";
// common
import * as Forms from "../../../common/forms/BootstrapForms";
import * as toastHelper from "../../../common/toastHelper";
import { ValidationVisibilityEnum } from "../../../common/validation/ValidationModel";
import { StatusMessagesDto } from "../../../api/Client";
// models
import { IRootViewModel, RootViewModel } from "./models/models";
import validator from "./models/validator";
import SubmitViewDetails from "./SubmitViewDetails";

interface IProps {
    onSubmitComplete: (statusMessages: StatusMessagesDto) => void;
    onCancel: () => void;
}

export default function SubmitView(props: IProps): React.JSX.Element {
    const nopimsBasketModel = useNopimsBasket();
    const [vm, setVm] = useState<IRootViewModel>(new RootViewModel(nopimsBasketModel.userToken!));

    // initial load
    useEffect(() => {
        SubmitActions.initialise(vm, setVm);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // On successful save close screen
    useEffect(() => {
        if (vm.statusMessages?.isSuccess) {
            props.onSubmitComplete(vm.statusMessages!);
        }
    }, [vm.statusMessages]); // eslint-disable-line react-hooks/exhaustive-deps

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    function onSubmit() {
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.None);
        SubmitActions.submitBasketCommand(vm, setVm);
    }

    return (
        <TaskLayout statusMessages={vm.statusMessages} isDirty={vm.isDirty} isBusy={vm.isBusy}>
            <Forms.Row>
                <Forms.HalfColumn>
                    You are requesting copies of <b>{nopimsBasketModel.items.length.toString()} documents</b>.
                </Forms.HalfColumn>
            </Forms.Row>
            <SubmitViewDetails vm={vm} setVm={setVm} validation={validation} onCancel={props.onCancel} onSubmit={onSubmit} />
        </TaskLayout>
    );
}
