// framework
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
// shell
import NopimsPageLayout from "../shell/NopimsPageLayout";
import { nopimsLhsNavItemEnum } from "../shell/NopimsLhsNav";
// common
import ODataAction from "../../common/odata/ODataAction";
import { useUserToken } from "../../common/shell/shellHelper";
import SelectedItems from "../../common/selectedItems/SelectedItems";
import SelectedItemsHelper from "../../common/selectedItems/SelectedItemsHelper";
// models
import { ISurveySearchDataRow } from "./surveySearch/models";
// Controller
import SurveySearchController from "./surveySearch/Controller";
import ReportsController from "./reports/Controller";
import SurveyDataController from "./surveyData/Controller";
import SeismicLinesController from "./seismicLines/Controller";

export default function Controller(): React.JSX.Element {
    const userToken = useUserToken();

    const [selectionVersion, setSelectionVersion] = useState<number>(1);
    const [selectedItems, setSelectedItems] = useState<Array<ISurveySearchDataRow>>([]);
    const selectedItemsHelper = new SelectedItemsHelper<ISurveySearchDataRow>(
        selectedItems,
        setSelectedItems,
        (item1: ISurveySearchDataRow, item2: ISurveySearchDataRow) => item1.AcquisitionId === item2.AcquisitionId
    );

    return (
        <NopimsPageLayout title={["Surveys Search"]} lhsNavItem={nopimsLhsNavItemEnum.Surveys} showGuidance={true}>
            <Tabs>
                <Tab title="Surveys Search" eventKey="SurveySearch">
                    <SurveySearchController selectedItems={selectedItems} onAdd={(items) => selectedItemsHelper.TryAddAll(items)} onRemove={(item) => selectedItemsHelper.TryRemove(item)} />
                </Tab>
                <Tab title="Reports" eventKey="Reports" disabled={selectedItems.length === 0}>
                    <ReportsController selectionVersion={selectionVersion} />
                </Tab>
                <Tab title="Survey Data" eventKey="SurveyData" disabled={selectedItems.length === 0}>
                    <SurveyDataController selectionVersion={selectionVersion} />
                </Tab>
                <Tab title="Seismic Lines" eventKey="Seismic Lines" disabled={selectedItems.length === 0}>
                    <SeismicLinesController selectionVersion={selectionVersion} />
                </Tab>
            </Tabs>
            <SelectedItems
                title="Acquisition"
                showContainer={true}
                items={selectedItems}
                getItemTitle={(i) => i.AcquisitionIdentifier + " " + i.AcquisitionName}
                onItemRemoved={(i) => selectedItemsHelper.TryRemove(i)}
                onItemsCleared={() => selectedItemsHelper.Clear()}
            />
            {userToken && (
                <ODataAction
                    url="odata/v1/public/nopims/survey/PublicNopimsSurvey/SetAcquisitionSelections"
                    payload={{ userToken: userToken, acquisitionIds: selectedItems.map((i) => i.AcquisitionId) }}
                    onActioned={() => setSelectionVersion(selectionVersion + 1)}
                />
            )}
        </NopimsPageLayout>
    );
}
