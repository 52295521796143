// framework
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
// shell
import NopimsPageLayout from "../shell/NopimsPageLayout";
import { nopimsLhsNavItemEnum } from "../shell/NopimsLhsNav";
// common
import ODataAction from "../../common/odata/ODataAction";
import { useUserToken } from "../../common/shell/shellHelper";
import SelectedItems from "../../common/selectedItems/SelectedItems";
import SelectedItemsHelper from "../../common/selectedItems/SelectedItemsHelper";
// models
import { IReprocessingSearchDataRow } from "./reprocessingSearch/models";
// Controller
import ReprocessingSearchController from "./reprocessingSearch/Controller";
import ReportsController from "./reports/Controller";
import SurveyDataController from "./surveyData/Controller";

export default function Controller(): React.JSX.Element {
    const userToken = useUserToken();

    const [selectionVersion, setSelectionVersion] = useState<number>(1);
    const [selectedItems, setSelectedItems] = useState<Array<IReprocessingSearchDataRow>>([]);
    const selectedItemsHelper = new SelectedItemsHelper<IReprocessingSearchDataRow>(
        selectedItems,
        setSelectedItems,
        (item1: IReprocessingSearchDataRow, item2: IReprocessingSearchDataRow) => item1.ReprocessingProjectId === item2.ReprocessingProjectId
    );

    return (
        <NopimsPageLayout title={["Reprocessing Search"]} lhsNavItem={nopimsLhsNavItemEnum.Reprocessing} showGuidance={true}>
            <Tabs>
                <Tab title="Reprocessing Search" eventKey="ReprocessingSearch">
                    <ReprocessingSearchController selectedItems={selectedItems} onAdd={(items) => selectedItemsHelper.TryAddAll(items)} onRemove={(item) => selectedItemsHelper.TryRemove(item)} />
                </Tab>
                <Tab title="Reports" eventKey="Reports" disabled={selectedItems.length === 0}>
                    <ReportsController selectionVersion={selectionVersion} />
                </Tab>
                <Tab title="Survey Data" eventKey="SurveyData" disabled={selectedItems.length === 0}>
                    <SurveyDataController selectionVersion={selectionVersion} />
                </Tab>
            </Tabs>
            <SelectedItems
                title="Project"
                showContainer={true}
                items={selectedItems}
                getItemTitle={(i) => `${i.ReprocessingProjectIdentifier} ${i.ReprocessingProjectName}`}
                onItemRemoved={(i) => selectedItemsHelper.TryRemove(i)}
                onItemsCleared={() => selectedItemsHelper.Clear()}
            />
            {userToken && (
                <ODataAction
                    url="odata/v1/public/nopims/reprocessing/PublicNopimsReprocessings/SetProjectSelections"
                    payload={{ userToken: userToken, reprocessingProjectIds: selectedItems.map((i) => i.ReprocessingProjectId) }}
                    onActioned={() => setSelectionVersion(selectionVersion + 1)}
                />
            )}
        </NopimsPageLayout>
    );
}
