import { takeEvery, call, put } from "redux-saga/effects";
import * as Client from "../../../api/Client";
import { createOpggsTitlesClient } from "../../../api/clientFactory";
import { simpleCall } from "../../../api/callHelper";
import { setBusyAction, clearBusyAction } from "../../../shell/redux/shellActions";
import * as Actions from "./openTitleActions";

export default function* watcherSaga() {
    yield takeEvery(Actions.openTitleActions.GetTitleIdRequest, workerSaga);
}

function* workerSaga(action: Actions.IOpenTitleActions) {
    try {
        // start query
        yield put(setBusyAction());
        const response: Client.GetPublicOpenTitleIdResponseDto = yield call(query, action.titleNumber!);
        // post response
        yield put(Actions.getTitleIdResponse(response.titleId));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function query(titleNumber: string): Promise<Client.GetPublicOpenTitleIdResponseDto> {
    const client = createOpggsTitlesClient();
    const request = new Client.GetPublicOpenTitleIdRequestDto({ titleNumber: titleNumber });
    const response = await simpleCall(() => client.getPublicOpenTitleId(request));
    return response;
}
