// framework
import React, { ReactNode } from "react";
// other
import { DownloadIcon } from "@primer/octicons-react";

export default function DownloadLinkView(props: {
    href: string | undefined;
    className?: string | undefined;
    style?: React.CSSProperties | undefined;
    children: ReactNode;
    title?: string | undefined;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    onContextMenu?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    onMouseDown?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}) {
    return (
        <a
            href={props.href}
            className={props.className}
            style={props.style}
            title={props.title ?? "Download the file."}
            onClick={props.onClick}
            onContextMenu={props.onContextMenu}
            onMouseDown={props.onMouseDown}
        >
            <span>{props.children}</span>
            <DownloadIcon className="ms-1" size={12} verticalAlign="text-top" />
        </a>
    );
}
