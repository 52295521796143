import React from "react";
import PageLayout from "../../common/shell/PageLayout";
import NopimsLhsNav, { nopimsLhsNavItemEnum } from "./NopimsLhsNav";
import ShoppingCartSummaryController from "../basket/summary/Controller";
import { usePortalLink } from "../../common/shell/shellHelper";
import { ExternalLinkEnum } from "../../api/Client";

interface IProps {
    title: (string | undefined)[];
    titleReactNode?: React.ReactNode | undefined;
    children: React.ReactNode;
    lhsNavItem?: nopimsLhsNavItemEnum | undefined;
    showGuidance?: boolean;
}

export default function NopimsPageLayout(props: IProps): React.JSX.Element {
    const helpUrl = usePortalLink(ExternalLinkEnum.NeatsNopimsPublicPortalGuidance);

    return (
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col-1" style={{ minWidth: 225, maxWidth: 225 }}>
                    <div className="mb-4">
                        <NopimsLhsNav item={props.lhsNavItem} />
                    </div>
                    <div>
                        <ShoppingCartSummaryController />
                    </div>
                </div>
                <div className="col" style={{ minWidth: 320 }}>
                    <PageLayout title={props.title} titleReactNode={props.titleReactNode} helpUrl={helpUrl}>
                        {props.children}{" "}
                    </PageLayout>
                </div>
            </div>
        </div>
    );
}
