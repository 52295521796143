// framework
import React from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";
// api
import * as Client from "../../../../api/Client";
// common
import * as Forms from "../../../../common/forms/BootstrapForms";
// models
import { IRootViewModel } from "../models/models";

interface IProps {
    vm: IRootViewModel;
    onReset: () => void;
}

export default function CoreCuttingsRequestCreateSubmittedView(props: IProps): React.JSX.Element {
    const { vm } = props;
    const coreCuttingsRequest = vm.coreCuttingsRequest;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Contact Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="honorificField" label="Title" value={coreCuttingsRequest.honorific?.name} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="firstNameField" label="First Name" value={coreCuttingsRequest.firstName} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="lastNameField" label="Last Name" value={coreCuttingsRequest.lastName} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="companyNameField" label="Company" value={coreCuttingsRequest.companyName} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="contactNumberField" label="Contact Number" value={coreCuttingsRequest.contactNumber} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="emailAddressField" label="Email" value={coreCuttingsRequest.emailAddress} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <h2 className="card-title">Address Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.FullColumn>
                                <Forms.ReadonlyField id="streetAddressField" label="Street Address" value={coreCuttingsRequest.streetAddress} />
                            </Forms.FullColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.HalfColumn>
                                <Forms.ReadonlyField id="suburbField" label="Suburb" value={coreCuttingsRequest.suburb} />
                            </Forms.HalfColumn>
                            <Forms.HalfColumn>
                                <Forms.ReadonlyField id="postcodeField" label="Postcode" value={coreCuttingsRequest.postcode} />
                            </Forms.HalfColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.HalfColumn>
                                <Forms.ReadonlyField id="countrySubDivisionField" label="State/Territory" value={coreCuttingsRequest.countrySubDivision?.name} />
                            </Forms.HalfColumn>
                            <Forms.HalfColumn>
                                <Forms.ReadonlyField id="countryField" label="Country" value={coreCuttingsRequest.country?.name} />
                            </Forms.HalfColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <h2 className="card-title">Academia Information</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.QuarterColumn>
                                <Forms.Element label="Academia">
                                    <Forms.ReadonlyRadioButton name="isAcademia" id="isAcademiaYesField" label="Yes" inline checked={coreCuttingsRequest.isAcademia === true} />
                                    <Forms.ReadonlyRadioButton name="isAcademia" id="isAcademiaNoField" label="No" inline checked={coreCuttingsRequest.isAcademia === false} />
                                </Forms.Element>
                            </Forms.QuarterColumn>
                        </Forms.Row>
                    </Forms.Group>
                    {coreCuttingsRequest.isAcademia && (
                        <>
                            <h3 className="card-title">Supervisor Contact Details</h3>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="supervisorHonorificField" label="Title" value={coreCuttingsRequest.supervisorHonorific?.name} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="supervisorFirstNameField" label="First Name" value={coreCuttingsRequest.supervisorFirstName} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="supervisorLastNameField" label="Last Name" value={coreCuttingsRequest.supervisorLastName} />
                                    </Forms.ThirdColumn>
                                </Forms.Row>
                            </Forms.Group>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="supervisorUniversityNameField" label="University" value={coreCuttingsRequest.supervisorUniversityName} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="supervisorContactNumberField" label="Contact Number" value={coreCuttingsRequest.supervisorContactNumber} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="supervisorEmailAddressField" label="Email" value={coreCuttingsRequest.supervisorEmailAddress} />
                                    </Forms.ThirdColumn>
                                </Forms.Row>
                            </Forms.Group>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="supervisorSchoolNameField" label="School/Field" value={coreCuttingsRequest.supervisorSchoolName} />
                                    </Forms.ThirdColumn>
                                </Forms.Row>
                            </Forms.Group>
                        </>
                    )}
                    <h2 className="card-title">Study Information</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.FullColumn>
                                <Forms.ReadonlyTextArea
                                    id="studyDescriptionield"
                                    label="Please provide a brief description of the study/analysis you intend to undertake."
                                    value={coreCuttingsRequest.studyDescription}
                                />
                            </Forms.FullColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <h2 className="card-title">Required Samples</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.FullColumn>
                                {vm.coreCuttingsRequest.samples && vm.coreCuttingsRequest.samples.some((s) => s) && (
                                    <div className="mt-3">
                                        <Grid data={vm.coreCuttingsRequest.samples}>
                                            <GridColumn field="borehole.displayText" title="Borehole" width={600} />
                                            <GridColumn field="intervalTop" title="Interval Top (m)" width={200} />
                                            <GridColumn field="intervalBottom" title="Interval Bottom (m)" width={200} />
                                            <GridColumn
                                                field="sampleTypes"
                                                title="Sample Types"
                                                width={800}
                                                cell={(props) => (
                                                    <CustomGridCell gridCellProps={props}>
                                                        {props.dataItem.sampleTypes && (
                                                            <p className="form-label">
                                                                {props.dataItem.sampleTypes
                                                                    .map((st: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseSampleTypeDto) => st.name)
                                                                    .join(", ")}
                                                            </p>
                                                        )}
                                                    </CustomGridCell>
                                                )}
                                            />
                                        </Grid>
                                    </div>
                                )}
                            </Forms.FullColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <div className="mt-3">
                        <button className="btn btn-primary me-2" type="button" onClick={() => props.onReset()}>
                            New
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
