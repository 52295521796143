/**
 * Determines which part of the string to truncate
 */
export enum TruncatePosition {
    /**
     * Extract the truncated string from the beginning of the string
     */
    Start,

    /**
     * Extract the truncated string from the e of the string
     */
    End,
}

export function truncate(value: string, length: number, startPosition?: TruncatePosition | undefined): string {
    let truncatedValue: string = "";

    // if length is zero or less then return an empty string
    if (length <= 0) return truncatedValue;
    // return the string if it's length is less than the truncated length
    if (value.length <= length) return value;

    // strip out new line characters when truncating the string
    value = value.replace(/[\r\n]+/g, " ");

    switch (startPosition ?? TruncatePosition.Start) {
        case TruncatePosition.Start:
            truncatedValue = value.substring(0, length).trim();
            return `${truncatedValue}…`;

        case TruncatePosition.End:
            truncatedValue = value.substring(value.length - length).trim();
            return `…${truncatedValue}`;
    }
}
