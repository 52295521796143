// framework
import React, { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// other
import { LinkIcon } from "@primer/octicons-react";
import SimpleAlert from "../simpleAlerts/SimpleAlertView";
import { getServicesUri } from "../../api/uriHelper";

export default function CopyODataLinkView(props: { title: string; oDataPath: string; toolTip?: string | undefined }) {
    const [showDialog, setShowDialog] = useState(false);
    const [showCopied, setShowCopied] = useState(false);

    const servicesUri: string = getServicesUri();
    const href: string = servicesUri + props.oDataPath;

    useEffect(() => {
        setShowCopied(false);
    }, [showDialog]);

    const linkTextRef = React.createRef<HTMLInputElement>();

    function copyLinkDialog(href: string) {
        return (
            <Dialog title="Copy Link" onClose={() => setShowDialog(false)}>
                {showCopied && <SimpleAlert message="Link text has been copied to the clipboard." alertType="success" dismissable={false} />}
                <span aria-label="Link text">
                    <input ref={linkTextRef} className="form-control" type="text" value={href} readOnly style={{ width: "500px" }}></input>
                </span>
                <DialogActionsBar>
                    <span className="mx-3 my-2">
                        <button className="btn btn-outline-primary me-2" onClick={copyLinkTextToClipboard}>
                            Copy Link to Clipboard
                        </button>
                        <button className="btn btn-outline-primary" onClick={() => setShowDialog(false)}>
                            Close
                        </button>
                    </span>
                </DialogActionsBar>
            </Dialog>
        );
    }

    function copyLinkTextToClipboard() {
        const textBox = linkTextRef.current!;
        textBox.select();
        textBox.setSelectionRange(0, textBox.value.length);
        document.execCommand("copy");

        setShowCopied(true);
    }

    return (
        <>
            <button className="btn btn-outline-secondary me-2" title={`Get the ${props.toolTip ?? props.title} OData link.`} onClick={() => setShowDialog(true)}>
                <span>
                    <span>{props.title}</span>
                    <LinkIcon className="ms-1" size={12} verticalAlign="text-top" aria-label="Get the OData link." />
                </span>
            </button>
            {showDialog && copyLinkDialog(href)}
        </>
    );
}
