export function getTextFilter(hasNull: boolean): any[] {
    let filter = [
        { text: "filter.containsOperator", operator: "contains" },
        { text: "filter.startsWithOperator", operator: "startswith" },
    ];

    if (hasNull) {
        filter.push({ text: "filter.isNullOperator", operator: "isnull" });
        filter.push({ text: "filter.isNotNullOperator", operator: "isnotnull" });
    }

    return filter;
}

export function getDateFilter(hasNull: boolean): any[] {
    let filter = [
        { text: "filter.eqOperator", operator: "eq" },
        { text: "filter.afterOrEqualOperator", operator: "gte" },
        { text: "filter.beforeOrEqualOperator", operator: "lte" },
    ];

    if (hasNull) {
        filter.push({ text: "filter.isNullOperator", operator: "isnull" });
        filter.push({ text: "filter.isNotNullOperator", operator: "isnotnull" });
    }

    return filter;
}

export function getNumericFilter(hasNull: boolean): any[] {
    let filter = [
        { text: "filter.eqOperator", operator: "eq" },
        { text: "filter.gteOperator", operator: "gte" },
        { text: "filter.lteOperator", operator: "lte" },
    ];

    if (hasNull) {
        filter.push({ text: "filter.isNullOperator", operator: "isnull" });
        filter.push({ text: "filter.isNotNullOperator", operator: "isnotnull" });
    }

    return filter;
}
