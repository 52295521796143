import { formatDate, formatNumber } from "@telerik/kendo-intl";

// to be used for simple helper functions used globally across the app

export function toNoptaDateString(value: Date | undefined | null, valueIfNullOrUndefined: string = ""): string {
    return value ? formatDate(value, NoptaDateFormat, NoptaLocale) : valueIfNullOrUndefined;
}

export function toNoptaDatetimeString(value: Date | undefined | null, valueIfNullOrUndefined: string = ""): string {
    return value ? formatDate(value, NoptaDatetimeFormat, NoptaLocale) : valueIfNullOrUndefined;
}

export function toNoptaNumberString(value: number | undefined | null, format: string, unit: string = "", valueIfNullOrUndefined: string = ""): string {
    if (value === null || value === undefined) return valueIfNullOrUndefined;

    const valueString = formatNumber(value, format, NoptaLocale);
    return unit ? `${valueString} ${unit}` : valueString;
}

export function toNoptaIntegerString(value: number | undefined | null, unit: string = "", valueIfNullOrUndefined: string = ""): string {
    return toNoptaNumberString(value, "n0", unit, valueIfNullOrUndefined);
}

export function convertBooleanToRadioValue(bool: boolean | undefined, trueRadioValue: string, falseRadioValue: string): string | undefined {
    if (bool === undefined) return undefined;

    return bool ? trueRadioValue : falseRadioValue;
}

export function convertBytesToString(bytes: number): string {
    if (bytes < 1024) return `${bytes} bytes`;
    else if (bytes / 1024 < 1024) return `${toNoptaNumberString(bytes / 1024, "n2")} KB`;
    else if (bytes / 1024 / 1024 < 1024) return `${toNoptaNumberString(bytes / 1024 / 1024, "n2")} MB`;
    else if (bytes / 1024 / 1024 / 1024 < 1024) return `${toNoptaNumberString(bytes / 1024 / 1024 / 1024, "n2")} GB`;
    else return `${toNoptaNumberString(bytes / 1024 / 1024 / 1024 / 1024, "n2")} TB`;
}

export function getDateOffsetMinutes(date: Date | undefined): number | undefined {
    // for +08:00 this returns -480
    // 480 minutes = 8 hours
    // javascript is opposite to .NET, so we need to invert it!
    if (!date) return undefined;

    const offsetMinutes = date.getTimezoneOffset();
    return -offsetMinutes;
}

// locale
export const NoptaLocale = "en-AU";

// kendo library formats
// https://github.com/telerik/kendo-intl/blob/develop/docs/date-formatting/index.md#custom
export const NoptaDateFormat = "dd/MM/yyyy";
export const NoptaDateFormatForKendo = "{0:dd/MM/yyyy}";

export const NoptaDatetimeFormat = "dd/MM/yyyy hh:mm:ssa";
export const NoptaDatetimeFormatForKendo = "{0:dd/MM/yyyy hh:mm:ssa}";
