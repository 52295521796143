import * as Client from "./Client";
import * as UriHelper from "./uriHelper";

export function createOpggsApplicationsClient(): Client.PublicOpggsApplicationsServiceClient {
    return createAnonymousClient((config, uri) => new Client.PublicOpggsApplicationsServiceClient(config, uri));
}

export function createOpggsTitlesClient(): Client.PublicOpggsTitlesServiceClient {
    return createAnonymousClient((config, uri) => new Client.PublicOpggsTitlesServiceClient(config, uri));
}

export function createOpggsStorageFormationsClient(): Client.PublicOpggsStorageFormationsListServiceClient {
    return createAnonymousClient((config, uri) => new Client.PublicOpggsStorageFormationsListServiceClient(config, uri));
}

export function createOeiApplicationsClient(): Client.PublicOeiApplicationsServiceClient {
    return createAnonymousClient((config, uri) => new Client.PublicOeiApplicationsServiceClient(config, uri));
}

export function createOeiLicencesSearchClient(): Client.PublicOeiLicencesSearchServiceClient {
    return createAnonymousClient((config, uri) => new Client.PublicOeiLicencesSearchServiceClient(config, uri));
}

export function createOeiLicenceDetailsClient(): Client.PublicOeiLicenceDetailsServiceClient {
    return createAnonymousClient((config, uri) => new Client.PublicOeiLicenceDetailsServiceClient(config, uri));
}

export function createOeiLicenceOpenClient(): Client.PublicOeiLicenceOpenServiceClient {
    return createAnonymousClient((config, uri) => new Client.PublicOeiLicenceOpenServiceClient(config, uri));
}

export function createFeedbackClient(): Client.PublicFeedbackServiceClient {
    return createAnonymousClient((config, uri) => new Client.PublicFeedbackServiceClient(config, uri));
}

export function createCoreCuttingsRequestClient(): Client.PublicCoreCuttingsRequestServiceClient {
    return createAnonymousClient((config, uri) => new Client.PublicCoreCuttingsRequestServiceClient(config, uri));
}

export function createBasketClient(): Client.PublicNopimsBasketServiceClient {
    return createAnonymousClient((config, uri) => new Client.PublicNopimsBasketServiceClient(config, uri));
}

export function createShellClient(): Client.PublicShellServiceClient {
    return createAnonymousClient((config, uri) => new Client.PublicShellServiceClient(config, uri));
}

function createAnonymousClient<T extends Client.BaseClient>(factory: (config: Client.IConfig, uri: string) => T): T {
    // return client
    const uri: string = UriHelper.getServicesUri();
    const config: Client.IConfig = { getSessionCorrelation: () => getSessionCorrelation() };
    return factory(config, uri);
}

// builds a Session Correlation string by using the public user's token and a token that is unique to the browser session
// this will allow us to better correlate events performed by the user within a single session on their browser
export function getSessionCorrelation(): string {
    // get the user token  (this is set in Shell/UserTokenController)
    const userTokenKey: string = "UserToken";
    let userTokenValue = localStorage[userTokenKey] ?? "anon";

    // gets/sets the session id in session storage
    const sessionTokenKey: string = "SessionToken";
    let sessionTokenValue = sessionStorage[sessionTokenKey];
    if (!sessionTokenValue) {
        sessionTokenValue = crypto.randomUUID();
        sessionStorage[sessionTokenKey] = sessionTokenValue;
    }

    // return a combination of the user token and session token
    return `${userTokenValue}-${sessionTokenValue}`;
}
