// framework
import { combineReducers } from "redux";
import { IShellState, shellReducer } from "../shell/redux/shellReducers";
// user components
import { ITitleSearchState, titleSearchReducer } from "../opggs/title/titleSearch/redux/titleSearchReducers";
import { ITitleDetailsState, titleDetailsReducer } from "../opggs/title/titleDetails/redux/titleDetailsReducers";
import { IApplicationTrackingState, opggsApplicationTrackingReducer } from "../opggs/application/opggsApplicationTracking/redux/opggsApplicationTrackingReducers";
import { IApplicationTrackingDetailsState, opggsApplicationTrackingDetailsReducer } from "../opggs/application/opggsApplicationTrackingDetails/redux/opggsApplicationTrackingDetailsReducers";
import { IOpenTitleState, openTitleReducer } from "../open/title/redux/openTitleReducers";
import { IStorageFormationsListState, storageFormationsListReducer } from "../opggs/storageFormation/storageFormationsList/redux/storageFormationsListReducers";
import { ILicenceSearchState, licenceSearchReducer } from "../oei/licence/search/redux/licenceSearchReducers";
import { ILicenceDetailsState, licenceDetailsReducer } from "../oei/licence/details/redux/licenceDetailsReducers";
import { IOpenLicenceState, openLicenceReducer } from "../open/licence/redux/openLicenceReducers";
import { IOeiApplicationTrackingState, oeiApplicationTrackingReducer } from "../oei/application/oeiApplicationTracking/redux/oeiApplicationTrackingReducers";
import { IOeiApplicationTrackingDetailsState, oeiApplicationTrackingDetailsReducer } from "../oei/application/oeiApplicationTrackingDetails/redux/oeiApplicationTrackingDetailsReducers";
import { INopimsBasketModel } from "../nopims/basket/redux/model";
import { reducer as nopimsBasketReducer } from "../nopims/basket/redux/reducers";

export interface IRootReduxState {
    shell: IShellState;
    titleSearch: ITitleSearchState;
    titleDetails: ITitleDetailsState;
    applicationTracking: IApplicationTrackingState;
    applicationTrackingDetails: IApplicationTrackingDetailsState;
    openTitle: IOpenTitleState;
    storageFormationsList: IStorageFormationsListState;
    licenceSearch: ILicenceSearchState;
    licenceDetails: ILicenceDetailsState;
    openLicence: IOpenLicenceState;
    oeiApplicationTracking: IOeiApplicationTrackingState;
    oeiApplicationTrackingDetails: IOeiApplicationTrackingDetailsState;
    nopimsBasket: INopimsBasketModel;
}

// root reducer
// - combines all of the reducers and gives them mutually exclusive scopes
const rootReducer = combineReducers({
    // infrastructure
    shell: shellReducer,
    // areas
    titleSearch: titleSearchReducer,
    titleDetails: titleDetailsReducer,
    applicationTracking: opggsApplicationTrackingReducer,
    applicationTrackingDetails: opggsApplicationTrackingDetailsReducer,
    openTitle: openTitleReducer,
    storageFormationsList: storageFormationsListReducer,
    licenceSearch: licenceSearchReducer,
    licenceDetails: licenceDetailsReducer,
    openLicence: openLicenceReducer,
    oeiApplicationTracking: oeiApplicationTrackingReducer,
    oeiApplicationTrackingDetails: oeiApplicationTrackingDetailsReducer,
    nopimsBasket: nopimsBasketReducer,
});

export default rootReducer;
